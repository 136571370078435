import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_RESET,
  PRODUCT_DETAIL_REQUEST,
  PRODUCT_DETAIL_SUCCESS,
  PRODUCT_DETAIL_FAIL,
  PRODUCT_DETAIL_RESET,
  NEW_PRODUCT_ADD_REQUEST,
  NEW_PRODUCT_ADD_SUCCESS,
  NEW_PRODUCT_ADD_FAIL,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_RESET,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAIL,
} from "constants/catalogueConstants/productConstants";

export const productListReducer = (
  state = { productListDetails: [], loading: true },
  action
) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true };

    case PRODUCT_LIST_SUCCESS:
      return { loading: false, productListDetails: action.payload };

    case PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };

    case PRODUCT_LIST_RESET:
      return { productListDetails: [], loading: true };

    default:
      return state;
  }
};

export const addNewProductReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_PRODUCT_ADD_REQUEST:
      return { loading: true };

    case NEW_PRODUCT_ADD_SUCCESS:
      return { loading: false, newProductAddInfo: action.payload };

    case NEW_PRODUCT_ADD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const productDetailsReducer = (
  state = { productInfo: {}, loading: true },
  action
) => {
  switch (action.type) {
    case PRODUCT_DETAIL_REQUEST:
      return { ...state, loading: true };

    case PRODUCT_DETAIL_SUCCESS:
      return { loading: false, productInfo: action.payload };

    case PRODUCT_DETAIL_FAIL:
      return { loading: false, error: action.payload };

    case PRODUCT_DETAIL_RESET:
      return { productInfo: {} };

    default:
      return state;
  }
};

export const productUpdateReducer = (
  state = { productUpdateDetails: {} },
  action
) => {
  switch (action.type) {
    case PRODUCT_UPDATE_REQUEST:
      return { loading: true };

    case PRODUCT_UPDATE_SUCCESS:
      return {
        loading: false,
        updateSuccess: true,
        productUpdateDetails: action.payload,
      };

    case PRODUCT_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case PRODUCT_UPDATE_RESET:
      return { productUpdateDetails: [] };

    default:
      return state;
  }
};

export const productDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_DELETE_REQUEST:
      return { loading: true };

    case PRODUCT_DELETE_SUCCESS:
      return { loading: false, success: true };

    case PRODUCT_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
