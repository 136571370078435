import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getCurrentUserPermissions } from "api/userApis";
import { logout } from "actions/userActions";
import { useHistory } from "react-router-dom";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userLoginInfo } = userLogin;
  let [user, setUser] = useState(null);
  const history = useHistory();

  let setPermissions = async () => {
    let permissions = await getCurrentUserPermissions();
    setUser(permissions);
  };
  useEffect(() => {
    if (userLoginInfo) {
      setPermissions();
    }
    // else {
    //   dispatch(logout());
    //   history.push("/login");
    // }
  }, [dispatch, history, userLoginInfo]);

  let contextData = {
    user: user,
  };

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};
