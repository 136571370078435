export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_FAIL = "CATEGORY_LIST_FAIL";
export const CATEGORY_LIST_RESET = "CATEGORY_LIST_RESET";

export const CATEGORY_DETAIL_REQUEST = "CATEGORY_DETAIL_REQUEST";
export const CATEGORY_DETAIL_SUCCESS = "CATEGORY_DETAIL_SUCCESS";
export const CATEGORY_DETAIL_FAIL = "CATEGORY_DETAIL_FAIL";
export const CATEGORY_DETAIL_RESET = "CATEGORY_DETAIL_RESET";

export const NEW_CATEGORY_ADD_REQUEST = "NEW_CATEGORY_ADD_REQUEST";
export const NEW_CATEGORY_ADD_SUCCESS = "NEW_CATEGORY_ADD_SUCCESS";
export const NEW_CATEGORY_ADD_FAIL = "NEW_CATEGORY_ADD_FAIL";

export const CATEGORY_UPDATE_REQUEST = "CATEGORY_UPDATE_REQUEST";
export const CATEGORY_UPDATE_SUCCESS = "CATEGORY_UPDATE_SUCCESS";
export const CATEGORY_UPDATE_FAIL = "CATEGORY_UPDATE_FAIL";
export const CATEGORY_UPDATE_RESET = "CATEGORY_UPDATE_RESET";

export const CATEGORY_DELETE_REQUEST = "CATEGORY_DELETE_REQUEST";
export const CATEGORY_DELETE_SUCCESS = "CATEGORY_DELETE_SUCCESS";
export const CATEGORY_DELETE_FAIL = "CATEGORY_DELETE_FAIL";
