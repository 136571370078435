import {
  ADMIN_USER_DETAILS_FAIL,
  ADMIN_USER_DETAILS_REQUEST,
  ADMIN_USER_DETAILS_SUCCESS,
  NEW_USER_ADD_FAIL,
  NEW_USER_ADD_REQUEST,
  NEW_USER_ADD_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCCESS,
  USER_IS_ADMIN,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_RESET,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_RESET,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
} from "constants/userConstants";
import {
  getUserDetails as adminUserGetDetails,
  authenticate,
  createUser,
  getCurrentUserDetails,
  getCurrentUserPermissions,
  getUserGroupList,
  getUserPage,
  removeUser,
  signUP,
  updateProfile,
  updateUserDetails,
} from "api/userApis";
import { developLog, parseErrors } from "utils";

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    let payload = {
      email: email,
      password: password,
    };
    let response = await authenticate(payload);
    if (response.hasOwnProperty("response")) {
      if (response.response.status >= 400) {
        let errors_messages = parseErrors(response);
        dispatch({
          type: USER_LOGIN_FAIL,
          payload:
            response.response && response.response.data
              ? errors_messages
              : response,
        });
      }
    } else {
      let group_list = await getUserGroupList();

      let permissions = await getCurrentUserPermissions(
        response.user,
        group_list
      );

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: response.user,
      });
      dispatch({
        type: USER_IS_ADMIN,
        payload: permissions.group_id_mapping,
      });
    }
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error,
    });
  }
};

export const logout = () => (dispatch) => {
  // axiosInstance.defaults.headers["Authorization"] = null;

  dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_DETAILS_RESET });
  dispatch({ type: USER_LIST_RESET });
  dispatch({ type: USER_UPDATE_PROFILE_RESET });
  //   dispatch({ type: USER_IS_ADMIN_RESET });
  localStorage.clear();
  // let history = useHistory();
  // history.push('/')
};

export const register =
  (
    email,
    password1,
    password2,
    first_name,
    last_name,
    gender,
    phone_number,
    dob
  ) =>
  async (dispatch) => {
    try {
      dispatch(logout());
      dispatch({
        type: USER_REGISTER_REQUEST,
      });

      let payload = {
        email: email,
        password1: password1,
        password2: password2,
        gender: gender,
        phone_number: phone_number,
        dob: dob,
        first_name: first_name,
        last_name: last_name,
      };
      let response = await signUP(payload);
      if (response.hasOwnProperty("response")) {
        if (response.response.status >= 400) {
          let errors_messages = parseErrors(response);
          dispatch({
            type: USER_REGISTER_FAIL,
            payload:
              response.response && response.response.data
                ? errors_messages
                : response,
          });
        }
      } else {
        dispatch({
          type: USER_REGISTER_SUCCESS,
          payload: response,
        });
      }
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload: error,
      });
    }
  };

export const getUserDetails = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });

    let response = await getCurrentUserDetails();
    if (response.hasOwnProperty("response")) {
      if (response.response.status >= 400) {
        dispatch({
          type: USER_DETAILS_FAIL,
          payload:
            response.response && response.response.data.detail
              ? response.response.data.detail
              : response,
        });
        dispatch(logout());
      }
    } else {
      dispatch({
        type: USER_DETAILS_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: error,
    });
  }
};

export const updateUserProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    });

    let response = await updateProfile(user);
    if (response.hasOwnProperty("response")) {
      if (response.response.status >= 400) {
        let errors_messages = parseErrors(response);
        dispatch({
          type: USER_UPDATE_PROFILE_FAIL,
          payload:
            response.response && response.response.data
              ? errors_messages
              : response,
        });
      }
    } else {
      dispatch({
        type: USER_UPDATE_PROFILE_SUCCESS,
        payload: response,
      });
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload: error,
    });
  }
};

export const listUsers = (link) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });
    let response = await getUserPage(link);
    if (response.hasOwnProperty("response")) {
      if (response.response.status >= 400) {
        dispatch({
          type: USER_LIST_FAIL,
          payload:
            response.response && response.response.data.detail
              ? response.response.data.detail
              : response,
        });
      }
    } else {
      dispatch({
        type: USER_LIST_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: USER_LIST_FAIL,
      payload: error,
    });
  }
};

export const deleteUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    });

    let response = await removeUser(id);
    if (response.hasOwnProperty("response")) {
      if (response.response.status >= 400) {
        dispatch({
          type: USER_DELETE_FAIL,
          payload:
            response.response && response.response.data.detail
              ? response.response.data.detail
              : response,
        });
      }
    } else {
      dispatch({
        type: USER_DELETE_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: USER_DELETE_FAIL,
      payload: error,
    });
  }
};

export const updateUser =
  (id, userformdetails) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_UPDATE_REQUEST,
      });
      // developLog("Update data:", userformdetails);
      let response = await updateUserDetails(id, userformdetails);
      if (response.hasOwnProperty("response")) {
        if (response.response.status >= 400) {
          let errors_messages = parseErrors(response);
          dispatch({
            type: USER_UPDATE_FAIL,
            payload:
              response.response && response.response.data
                ? errors_messages
                : response,
          });
        }
      } else {
        dispatch({
          type: USER_UPDATE_SUCCESS,
          payload: response,
        });
      }
    } catch (error) {
      dispatch({
        type: USER_UPDATE_FAIL,
        payload: error,
      });
    }
  };

export const getAdminUserDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_USER_DETAILS_REQUEST,
    });

    let response = await adminUserGetDetails(id);
    if (response.hasOwnProperty("response")) {
      if (response.response.status >= 400) {
        dispatch({
          type: ADMIN_USER_DETAILS_FAIL,
          payload:
            response.response && response.response.data.detail
              ? response.response.data.detail
              : response,
        });
        dispatch(logout());
      }
    } else {
      dispatch({
        type: ADMIN_USER_DETAILS_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: ADMIN_USER_DETAILS_FAIL,
      payload: error,
    });
  }
};

export const createNewUser = (userFormData) => async (dispatch) => {
  try {
    dispatch({
      type: NEW_USER_ADD_REQUEST,
    });
    let response = await createUser(userFormData);
    if (response.hasOwnProperty("response")) {
      if (response.response.status >= 400) {
        let errors_messages = parseErrors(response);
        dispatch({
          type: NEW_USER_ADD_FAIL,
          payload:
            response.response && response.response.data
              ? errors_messages
              : response,
        });
      }
    } else {
      dispatch({
        type: NEW_USER_ADD_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: NEW_USER_ADD_FAIL,
      payload: error,
    });
  }
};
