// UIContext.js
import React, { createContext, useContext } from "react";
import {
  useBreakpointValue,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
const UIContext = createContext();

export const UIProvider = ({ children }) => {
  // Define your UI variables here
  const uiVariables = {
    colorMode: useColorMode(),
    paddingValue: useBreakpointValue({ base: "0.5rem", md: "2rem" }),
    minWidthValue: useBreakpointValue({ base: "40%", md: "80%" }),
    // paddingValue: useBreakpointValue({ base: "1rem", md: "2rem" }),
    // maxWidthValue: useBreakpointValue({ base: "100%", md: "96%" }),
    cellFontSize: useBreakpointValue({ base: "sm", md: "md" }),
    cellPadding: useBreakpointValue({ base: "2", md: "4" }),
    formLabelColor: useColorModeValue("gray.600", "gray.400"),
    errorTextColor: useColorModeValue("red.500", "red.300"),
    multiSelectStyles: {
      baseStyle: {
        bg: useColorModeValue("gray.100", "gray.700"), // Light and dark background for multi-select box
        color: "white", // Text color
        border: "1px solid", // Border
        borderColor: useColorModeValue("gray.200", "gray.600"), // Border color
        _hover: {
          bg: useColorModeValue("gray.200", "gray.600"), // Background color on hover
        },
      },

      // Add more styles if needed...
    },
  };

  return (
    <UIContext.Provider value={uiVariables}>{children}</UIContext.Provider>
  );
};

export const useUI = () => {
  const context = useContext(UIContext);
  if (context === undefined) {
    throw new Error("useUI must be used within a UIProvider");
  }
  return context;
};
