import { lazy } from "react";
const HomeScreen = lazy(() => import("screens/HomeScreen"));
const LoginScreen = lazy(() => import("screens/userScreens/LoginScreen"));
const RegisterScreen = lazy(() => import("screens/userScreens/RegisterScreen"));
const CorporateEnquiryScreen = lazy(() =>
  import("screens/userScreens/CorporateEnquiry")
);
const ForgotPasswordScreen = lazy(() =>
  import("screens/userScreens/ForgotPasswordScreen")
);
const ChangePasswordScreen = lazy(() =>
  import("screens/userScreens/ChangePasswordScreen")
);
const ResetPasswordScreen = lazy(() =>
  import("screens/userScreens/ResetPasswordScreen")
);
const ProfileScreen = lazy(() => import("screens/userScreens/ProfileScreen"));
const AdminDashboardScreen = lazy(() =>
  import("screens/userScreens/AdminDashboardScreen")
);
const UserListScreen = lazy(() =>
  import("screens/userScreens/userListScreen/UserListScreen")
);
const AddNewUserScreen = lazy(() =>
  import("screens/userScreens/addUserScreen/AddNewUserScreen")
);
const UserEditScreen = lazy(() =>
  import("screens/userScreens/editUserScreen/UserEditScreen")
);
const AccountConfirmEmailScreen = lazy(() =>
  import("screens/userScreens/AccountConfirmEmailScreen")
);
const DepartmentListScreen = lazy(() =>
  import(
    "screens/SupplierScreen/department/departmentListScreen/DepartmentListScreen"
  )
);
const AddNewDepartmentScreen = lazy(() =>
  import(
    "screens/SupplierScreen/department/addDepartmentScreen/AddNewDepartmentScreen"
  )
);
const DepartmentEditScreen = lazy(() =>
  import(
    "screens/SupplierScreen/department/editDepartmentScreen/DepartmentEditScreen"
  )
);
const SupplierListScreen = lazy(() =>
  import(
    "screens/SupplierScreen/supplier/supplierListScreen/SupplierListScreen"
  )
);
const AddNewSupplierScreen = lazy(() =>
  import(
    "screens/SupplierScreen/supplier/addSupplierScreen/AddNewSupplierScreen"
  )
);
const SupplierEditScreen = lazy(() =>
  import(
    "screens/SupplierScreen/supplier/editSupplierScreen/SupplierEditScreen"
  )
);
const CategoryListScreen = lazy(() =>
  import(
    "screens/catalogueScreens/categoryScreens/categoryListScreen/CategoryListScreen"
  )
);
const AddNewCategoryScreen = lazy(() =>
  import(
    "screens/catalogueScreens/categoryScreens/addCategoryScreen/AddNewCategoryScreen"
  )
);
const CategoryEditScreen = lazy(() =>
  import(
    "screens/catalogueScreens/categoryScreens/editCategoryScreen/CategoryEditScreen"
  )
);
const ProductTypeListScreen = lazy(() =>
  import(
    "screens/catalogueScreens/productTypeScreens/productTypeListScreen/ProductTypeListScreen"
  )
);
const AddNewProductTypeScreen = lazy(() =>
  import(
    "screens/catalogueScreens/productTypeScreens/addProductTypeScreen/AddNewProductTypeScreen"
  )
);
const ProductTypeEditScreen = lazy(() =>
  import(
    "screens/catalogueScreens/productTypeScreens/editProductTypeScreen/ProductTypeEditScreen"
  )
);
const UQCListScreen = lazy(() =>
  import("screens/catalogueScreens/UQCScreens/uqcListScreen/UQCListScreen")
);
const AddNewUQCScreen = lazy(() =>
  import("screens/catalogueScreens/UQCScreens/addUQCScreen/AddNewUQCScreen")
);
const UQCEditScreen = lazy(() =>
  import("screens/catalogueScreens/UQCScreens/editUQCScreen/UQCEditScreen")
);
const UnitListScreen = lazy(() =>
  import("screens/catalogueScreens/unitScreens/unitListScreen/UnitListScreen")
);
const AddNewUnitScreen = lazy(() =>
  import("screens/catalogueScreens/unitScreens/addUnitScreen/AddNewUnitScreen")
);
const UnitEditScreen = lazy(() =>
  import("screens/catalogueScreens/unitScreens/editUnitScreen/UnitEditScreen")
);
const CustomizationListScreen = lazy(() =>
  import(
    "screens/catalogueScreens/customizationScreens/customizationListScreen/CustomizationListScreen"
  )
);
const AddNewCustomizationScreen = lazy(() =>
  import(
    "screens/catalogueScreens/customizationScreens/addCustomizationScreen/AddNewCustomizationScreen"
  )
);
const CustomizationEditScreen = lazy(() =>
  import(
    "screens/catalogueScreens/customizationScreens/editCustomizationScreen/CustomizationEditScreen"
  )
);
const ProductListScreen = lazy(() =>
  import("screens/catalogueScreens/productScreens/ProductListScreen")
);
const AddNewProductScreen = lazy(() =>
  import("screens/catalogueScreens/productScreens/AddNewProductScreen")
);
const ProductEditScreen = lazy(() =>
  import("screens/catalogueScreens/productScreens/ProductEditScreen")
);
const ProductAddToCart = lazy(() => import("components/ProductCard"));
const ShoppingCart = lazy(() =>
  import("screens/digitalOrderScreens/digitalOrderCart")
);
const ShippinAddress = lazy(() =>
  import("screens/digitalOrderScreens/addressListScreen")
);
const ProductDetail = lazy(() =>
  import("screens/catalogueScreens/productScreens/ProductDetail")
);

const CompanyListScreen = lazy(() =>
  import("screens/companyScreens/company/companyListScreens/CompanyListScreen")
);
const AddNewCompanyScreen = lazy(() =>
  import("screens/companyScreens/company/addCompanyScreen/AddNewCompanyScreen")
);
const EditCompanyScreen = lazy(() =>
  import("screens/companyScreens/company/editCompanyScreen/CompanyEditScreen")
);
const AddShippingAddressScreen = lazy(() =>
  import("screens/digitalOrderScreens/addAddressScreen")
);
const EditShippingAddressScreen = lazy(() =>
  import("screens/digitalOrderScreens/editAddressScreen")
);
const CartSummaryScreen = lazy(() =>
  import("screens/digitalOrderScreens/cartSummaryScreen")
);
const MyOrdersScreen = lazy(() =>
  import("screens/digitalOrderScreens/myOrders")
);
const PaymentFailedScreen = lazy(() =>
  import("screens/digitalOrderScreens/paymentFailed")
);
const PaymentSuccessScreen = lazy(() =>
  import("screens/digitalOrderScreens/paymentSuccess")
);
const OrderDetailScreen = lazy(() =>
  import("screens/digitalOrderScreens/orderDetailsScreen")
);
const CustomerInvoiceScreen = lazy(() =>
  import("screens/invoiceScreens/CustomerInvoice")
);
const AdminProductScreen = lazy(() =>
  import("screens/catalogueScreens/productScreens/AdminViewProduct")
);
const SearchResultScreen = lazy(() =>
  import("screens/catalogueScreens/productScreens/SearchResultsScreen")
);
const ImageSearchResultScreen = lazy(() =>
  import("screens/catalogueScreens/productScreens/ImageSearchResultsScreen")
);
const ProductsByCategoryScreen = lazy(() =>
  import("screens/catalogueScreens/productScreens/ProductsByCategory")
);
const ProductsByBrandScreen = lazy(() =>
  import("screens/catalogueScreens/productScreens/ProductsByBrand")
);
const AdminProductListScreen = lazy(() =>
  import("screens/catalogueScreens/productScreens/AdminProductListScreen")
);
const AdminProductsBySupplierListScreen = lazy(() =>
  import("screens/catalogueScreens/productScreens/ProductsBySupplier")
);
const DigitalOrdersListScreen = lazy(() =>
  import("screens/digitalOrderScreens/digitalOrderList")
);
const AdminDigitalOrderDetailScreen = lazy(() =>
  import("screens/digitalOrderScreens/digitalOrderDetailsScreen")
);
const InvoiceListScreen = lazy(() =>
  import("screens/invoiceScreens/invoiceList")
);
const ViewInvoiceScreen = lazy(() =>
  import("screens/invoiceScreens/viewInvoice")
);
const CreateInvoiceScreen = lazy(() =>
  import("screens/invoiceScreens/createInvoice")
);
const EditInvoiceScreen = lazy(() =>
  import("screens/invoiceScreens/editInvoice")
);
const AdminQuotationCartScreen = lazy(() =>
  import("screens/quotationScreens/adminScreens/quotationCart")
);
const AdminQuotationListScreen = lazy(() =>
  import("screens/quotationScreens/adminScreens/quotationRequestResponseList")
);
const QuotationCartScreen = lazy(() =>
  import("screens/quotationScreens/userScreens/quotationRequestCart")
);
const QuotationListScreen = lazy(() =>
  import("screens/quotationScreens/userScreens/quotationRequestResponseList")
);
const ViewQuotationScreen = lazy(() =>
  import("screens/quotationScreens/userScreens/viewQuotation")
);
const PurchaseOrderCartScreen = lazy(() =>
  import("screens/purchaseOrderScreens/purchaseOrderCart")
);
const PurchaseOrderListScreen = lazy(() =>
  import("screens/purchaseOrderScreens/purchaseOrderList")
);
const PurchaseOrderDetailsScreen = lazy(() =>
  import("screens/purchaseOrderScreens/purchaseOrderDetails")
);
const AdminOfflineOrderCartScreen = lazy(() =>
  import("screens/offlineOrderScreens/offlineOrderCart")
);
const OfflineOrdersListScreen = lazy(() =>
  import("screens/offlineOrderScreens/offlineOrderList")
);
const OfflineOrderDetailsScreen = lazy(() =>
  import("screens/offlineOrderScreens/offlineOrderDetails")
);

const AboutScreen = lazy(() => import("screens/About.js"));
const TermsAndConditionScreen = lazy(() =>
  import("screens/TermsAndConditions.js")
);
const routes = [
  {
    path: "/",
    component: HomeScreen,
    routeType: "public",
  },
  {
    path: "/terms-conditions",
    component: TermsAndConditionScreen,
    routeType: "public",
  },
  {
    path: "/about",
    component: AboutScreen,
    routeType: "public",
  },
  {
    path: "/register",
    component: RegisterScreen,
    routeType: "public",
  },
  {
    path: "/corporate-enquiry",
    component: CorporateEnquiryScreen,
    routeType: "public",
  },
  {
    path: "/login",
    component: LoginScreen,
    routeType: "public",
  },
  {
    path: "/account-confirm-email/:key",
    component: AccountConfirmEmailScreen,
    routeType: "public",
  },

  {
    path: "/forgot-password",
    component: ForgotPasswordScreen,
    routeType: "public",
  },
  {
    path: "/password-reset/:uid/:token",
    component: ResetPasswordScreen,
    routeType: "public",
  },
  {
    path: "/products/:category",
    component: ProductsByCategoryScreen,
    routeType: "public",
  },
  {
    path: "/brands/:brandSlug",
    component: ProductsByBrandScreen,
    routeType: "public",
  },
  {
    path: "/change-password",
    component: ChangePasswordScreen,
    routeType: "private",
  },
  {
    path: "/profile",
    component: ProfileScreen,
    routeType: "private",
  },
  {
    path: "/admin/dashboard",
    component: AdminDashboardScreen,
    routeType: "private",
  },

  {
    path: "/admin/user-list",
    component: UserListScreen,
    routeType: "private",
  },

  {
    path: "/admin/add-new-user",
    component: AddNewUserScreen,
    routeType: "private",
  },
  {
    path: "/admin/user/:id/edit",
    component: UserEditScreen,
    routeType: "private",
  },
  {
    path: "/admin/supplier/department-list",
    component: DepartmentListScreen,
    routeType: "private",
  },
  {
    path: "/admin/supplier/add-new-department",
    component: AddNewDepartmentScreen,
    routeType: "private",
  },
  {
    path: "/admin/supplier/department/:slug/edit",
    component: DepartmentEditScreen,
    routeType: "private",
  },
  {
    path: "/admin/supplier/supplier-list",
    component: SupplierListScreen,
    routeType: "private",
  },
  {
    path: "/admin/supplier/add-new-supplier",
    component: AddNewSupplierScreen,
    routeType: "private",
  },
  {
    path: "/admin/supplier/supplier/:slug/edit",
    component: SupplierEditScreen,
    routeType: "private",
  },

  {
    path: "/admin/catalogue/category-list",
    component: CategoryListScreen,
    routeType: "private",
  },
  {
    path: "/admin/catalogue/category/add-new",
    component: AddNewCategoryScreen,
    routeType: "private",
  },
  {
    path: "/admin/catalogue/category/:slug/edit",
    component: CategoryEditScreen,
    routeType: "private",
  },
  {
    path: "/admin/catalogue/product-type-list",
    component: ProductTypeListScreen,
    routeType: "private",
  },
  {
    path: "/admin/catalogue/productType/add-new",
    component: AddNewProductTypeScreen,
    routeType: "private",
  },
  {
    path: "/admin/catalogue/productType/:id/edit",
    component: ProductTypeEditScreen,
    routeType: "private",
  },
  {
    path: "/admin/catalogue/UQC-list",
    component: UQCListScreen,
    routeType: "private",
  },
  {
    path: "/admin/catalogue/UQC/add-new",
    component: AddNewUQCScreen,
    routeType: "private",
  },
  {
    path: "/admin/catalogue/UQC/:id/edit",
    component: UQCEditScreen,
    routeType: "private",
  },
  {
    path: "/admin/catalogue/unit-list",
    component: UnitListScreen,
    routeType: "private",
  },
  {
    path: "/admin/catalogue/Unit/add-new",
    component: AddNewUnitScreen,
    routeType: "private",
  },
  {
    path: "/admin/catalogue/Unit/:id/edit",
    component: UnitEditScreen,
    routeType: "private",
  },
  {
    path: "/admin/catalogue/customization-list",
    component: CustomizationListScreen,
    routeType: "private",
  },
  {
    path: "/admin/catalogue/customization/add-new",
    component: AddNewCustomizationScreen,
    routeType: "private",
  },
  {
    path: "/admin/catalogue/customization/:id/edit",
    component: CustomizationEditScreen,
    routeType: "private",
  },
  {
    path: "/catalogue/all-products",
    component: ProductListScreen,
    routeType: "public",
  },
  {
    path: "/catalogue/all-products/product-detail/:slug/:vslug",
    component: ProductDetail,
    routeType: "public",
  },

  {
    path: "/admin/catalogue/product/add-new",
    component: AddNewProductScreen,
    routeType: "private",
  },
  {
    path: "/admin/catalogue/product/:slug/edit",
    component: ProductEditScreen,
    routeType: "private",
  },
  {
    path: "/admin/add-to-cart",
    component: ProductAddToCart,
    routeType: "private",
  },
  {
    path: "/cart",
    component: ShoppingCart,
    routeType: "private",
  },
  {
    path: "/shipping-address",
    component: ShippinAddress,
    routeType: "private",
  },
  {
    path: "/admin/products",
    component: AdminProductListScreen,
    routeType: "private",
  },
  {
    path: "/admin/products-by-supplier/:supplier_code/:supplier_name",
    component: AdminProductsBySupplierListScreen,
    routeType: "private",
  },
  {
    path: "/admin/company/companyList",
    component: CompanyListScreen,
    routeType: "private",
  },
  {
    path: "/admin/company/add-new",
    component: AddNewCompanyScreen,
    routeType: "private",
  },
  {
    path: "/admin/company/:slug/edit",
    component: EditCompanyScreen,
    routeType: "private",
  },
  {
    path: "/admin/digital-orders",
    component: DigitalOrdersListScreen,
    routeType: "private",
  },
  {
    path: "/admin/digital-orders/:orderid",
    component: AdminDigitalOrderDetailScreen,
    routeType: "private",
  },
  {
    path: "/invoices",
    component: InvoiceListScreen,
    routeType: "private",
  },
  {
    path: "/invoices/:invoiceid",
    component: ViewInvoiceScreen,
    routeType: "private",
  },
  {
    path: "/my-invoices/:invoiceid",
    component: CustomerInvoiceScreen,
    routeType: "private",
  },
  {
    path: "/invoices/create/:ordertype/:orderid/",
    component: CreateInvoiceScreen,
    routeType: "private",
  },
  {
    path: "/invoices/edit/:invoiceid/",
    component: EditInvoiceScreen,
    routeType: "private",
  },
  {
    path: "/admin/quotation-cart",
    component: AdminQuotationCartScreen,
    routeType: "private",
  },
  {
    path: "/admin/quotations",
    component: AdminQuotationListScreen,
    routeType: "private",
  },
  {
    path: "/quotation-cart",
    component: QuotationCartScreen,
    routeType: "private",
  },
  {
    path: "/myquotations",
    component: QuotationListScreen,
    routeType: "private",
  },
  {
    path: "/quotations/:quotationid",
    component: ViewQuotationScreen,
    routeType: "private",
  },
  {
    path: "/admin/purchase-order-cart",
    component: PurchaseOrderCartScreen,
    routeType: "private",
  },
  {
    path: "/admin/purchase-orders",
    component: PurchaseOrderListScreen,
    routeType: "private",
  },
  {
    path: "/admin/purchase-orders/:orderid",
    component: PurchaseOrderDetailsScreen,
    routeType: "private",
  },
  {
    path: "/add-shipping-address",
    component: AddShippingAddressScreen,
    routeType: "private",
  },
  {
    path: "/shipping-address/:address_id",
    component: EditShippingAddressScreen,
    routeType: "private",
  },
  {
    path: "/cart-summary",
    component: CartSummaryScreen,
    routeType: "private",
  },
  {
    path: "/my-orders",
    component: MyOrdersScreen,
    routeType: "private",
  },
  {
    path: "/my-orders/:orderid",
    component: OrderDetailScreen,
    routeType: "private",
  },
  {
    path: "/admin/product-details/:slug/:vslug",
    component: AdminProductScreen,
    routeType: "private",
  },
  {
    path: "/product-search/:keyword",
    component: SearchResultScreen,
    routeType: "public",
  },
  {
    path: "/product-image-search/",
    component: ImageSearchResultScreen,
    routeType: "public",
  },
  {
    path: "/admin/offline-order-cart",
    component: AdminOfflineOrderCartScreen,
    routeType: "private",
  },
  {
    path: "/admin/offline-orders",
    component: OfflineOrdersListScreen,
    routeType: "private",
  },
  {
    path: "/admin/offline-orders/:orderid",
    component: OfflineOrderDetailsScreen,
    routeType: "private",
  },

  //   Keep this in end for 404 page

  {
    path: "/payment-failure",
    component: PaymentFailedScreen,
    routeType: "public",
  },
  {
    path: "/payment-success",
    component: PaymentSuccessScreen,
    routeType: "public",
  },
];
export default routes;
