import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_IS_ADMIN,
  USER_IS_ADMIN_RESET,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_RESET,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_RESET,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_RESET,
  ADMIN_USER_DETAILS_REQUEST,
  ADMIN_USER_DETAILS_SUCCESS,
  ADMIN_USER_DETAILS_FAIL,
  ADMIN_USER_DETAILS_RESET,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_RESET,
  NEW_USER_ADD_REQUEST,
  NEW_USER_ADD_SUCCESS,
  NEW_USER_ADD_FAIL,
} from "constants/userConstants";

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };

    case USER_LOGIN_SUCCESS:
      return { loading: false, userLoginInfo: action.payload };

    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

export const userGroupReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_IS_ADMIN:
      return { loading: false, userGroupInfo: action.payload };
    // Create userGroupInfoReset
    case USER_IS_ADMIN_RESET:
      return {};
    default:
      return state;
  }
};

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };

    case USER_REGISTER_SUCCESS:
      return { loading: false, userRegisterInfo: action.payload };

    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

export const userDetailsReducer = (
  state = { userProfileDetails: {} },
  action
) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { ...state, loading: true };

    case USER_DETAILS_SUCCESS:
      return { loading: false, userProfileDetails: action.payload };

    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    case USER_DETAILS_RESET:
      return { userProfileDetails: {} };

    default:
      return state;
  }
};

export const userUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true };

    case USER_UPDATE_PROFILE_SUCCESS:
      return {
        loading: false,
        success: true,
        userUpdatedProfileDetails: action.payload,
      };

    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload };

    case USER_UPDATE_PROFILE_RESET:
      return {};

    default:
      return state;
  }
};

export const userListReducer = (
  state = { usersListDetails: [], loading: true },
  action
) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true };

    case USER_LIST_SUCCESS:
      return { loading: false, usersListDetails: action.payload };

    case USER_LIST_FAIL:
      return { loading: false, error: action.payload };

    case USER_LIST_RESET:
      return { usersListDetails: [], loading: true };

    default:
      return state;
  }
};

export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loading: true };

    case USER_DELETE_SUCCESS:
      return { loading: false, success: true };

    case USER_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const userUpdateReducer = (
  state = { userUpdateDetails: {} },
  action
) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true };

    case USER_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        userUpdateDetails: action.payload,
      };

    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case USER_UPDATE_RESET:
      return { userUpdateDetails: [] };

    default:
      return state;
  }
};

export const adminUserDetailsReducer = (
  state = { adminUserProfileDetails: {}, loading: true },
  action
) => {
  switch (action.type) {
    case ADMIN_USER_DETAILS_REQUEST:
      return { ...state, loading: true };

    case ADMIN_USER_DETAILS_SUCCESS:
      return { loading: false, adminUserProfileDetails: action.payload };

    case ADMIN_USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    case ADMIN_USER_DETAILS_RESET:
      return { adminUserProfileDetails: {} };

    default:
      return state;
  }
};

export const addNewUserReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_USER_ADD_REQUEST:
      return { loading: true };

    case NEW_USER_ADD_SUCCESS:
      return { loading: false, newUserAddInfo: action.payload };

    case NEW_USER_ADD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
