import {
  UQC_LIST_REQUEST,
  UQC_LIST_SUCCESS,
  UQC_LIST_FAIL,
  UQC_LIST_RESET,
  UQC_DETAIL_REQUEST,
  UQC_DETAIL_SUCCESS,
  UQC_DETAIL_FAIL,
  UQC_DETAIL_RESET,
  NEW_UQC_ADD_REQUEST,
  NEW_UQC_ADD_SUCCESS,
  NEW_UQC_ADD_FAIL,
  UQC_UPDATE_REQUEST,
  UQC_UPDATE_SUCCESS,
  UQC_UPDATE_FAIL,
  UQC_UPDATE_RESET,
  UQC_DELETE_REQUEST,
  UQC_DELETE_SUCCESS,
  UQC_DELETE_FAIL,
} from "constants/catalogueConstants/uQcConstants";

export const UQCListReducer = (
  state = { UQCListDetails: [], loading: true },
  action
) => {
  switch (action.type) {
    case UQC_LIST_REQUEST:
      return { loading: true };

    case UQC_LIST_SUCCESS:
      return { loading: false, UQCListDetails: action.payload };

    case UQC_LIST_FAIL:
      return { loading: false, error: action.payload };

    case UQC_LIST_RESET:
      return { UQCListDetails: [], loading: true };

    default:
      return state;
  }
};

export const addNewUQCReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_UQC_ADD_REQUEST:
      return { loading: true };

    case NEW_UQC_ADD_SUCCESS:
      return { loading: false, newUQCAddInfo: action.payload };

    case NEW_UQC_ADD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const UQCDetailsReducer = (
  state = { UQCInfo: {}, loading: true },
  action
) => {
  switch (action.type) {
    case UQC_DETAIL_REQUEST:
      return { ...state, loading: true };

    case UQC_DETAIL_SUCCESS:
      return { loading: false, UQCInfo: action.payload };

    case UQC_DETAIL_FAIL:
      return { loading: false, error: action.payload };

    case UQC_DETAIL_RESET:
      return { UQCInfo: {} };

    default:
      return state;
  }
};

export const UQCUpdateReducer = (state = { UQCUpdateDetails: {} }, action) => {
  switch (action.type) {
    case UQC_UPDATE_REQUEST:
      return { loading: true };

    case UQC_UPDATE_SUCCESS:
      return {
        loading: false,
        updateSuccess: true,
        UQCUpdateDetails: action.payload,
      };

    case UQC_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case UQC_UPDATE_RESET:
      return { UQCUpdateDetails: [] };

    default:
      return state;
  }
};

export const UQCDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case UQC_DELETE_REQUEST:
      return { loading: true };

    case UQC_DELETE_SUCCESS:
      return { loading: false, success: true };

    case UQC_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
