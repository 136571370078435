import App from "App";
import { Provider } from "react-redux";
import React from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "reportWebVitals";
import store from "store";
import { developLog } from "utils";
const container = document.getElementById("root");
const root = createRoot(container);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then(function (registration) {
      developLog("Registration successful, scope is:", registration.scope);
    })
    .catch(function (err) {
      developLog("Service worker registration failed, error:", err);
    });
}
// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </React.StrictMode>
// );
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
