import {
  CUSTOMIZATION_LIST_REQUEST,
  CUSTOMIZATION_LIST_SUCCESS,
  CUSTOMIZATION_LIST_FAIL,
  CUSTOMIZATION_LIST_RESET,
  CUSTOMIZATION_DETAIL_REQUEST,
  CUSTOMIZATION_DETAIL_SUCCESS,
  CUSTOMIZATION_DETAIL_FAIL,
  CUSTOMIZATION_DETAIL_RESET,
  NEW_CUSTOMIZATION_ADD_REQUEST,
  NEW_CUSTOMIZATION_ADD_SUCCESS,
  NEW_CUSTOMIZATION_ADD_FAIL,
  CUSTOMIZATION_UPDATE_REQUEST,
  CUSTOMIZATION_UPDATE_SUCCESS,
  CUSTOMIZATION_UPDATE_FAIL,
  CUSTOMIZATION_UPDATE_RESET,
  CUSTOMIZATION_DELETE_REQUEST,
  CUSTOMIZATION_DELETE_SUCCESS,
  CUSTOMIZATION_DELETE_FAIL,
} from "constants/catalogueConstants/customizationConstants";

export const customizationListReducer = (
  state = { customizationListDetails: [], loading: true },
  action
) => {
  switch (action.type) {
    case CUSTOMIZATION_LIST_REQUEST:
      return { loading: true };

    case CUSTOMIZATION_LIST_SUCCESS:
      return { loading: false, customizationListDetails: action.payload };

    case CUSTOMIZATION_LIST_FAIL:
      return { loading: false, error: action.payload };

    case CUSTOMIZATION_LIST_RESET:
      return { customizationListDetails: [], loading: true };

    default:
      return state;
  }
};

export const addNewCustomizationReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_CUSTOMIZATION_ADD_REQUEST:
      return { loading: true };

    case NEW_CUSTOMIZATION_ADD_SUCCESS:
      return { loading: false, newCustomizationAddInfo: action.payload };

    case NEW_CUSTOMIZATION_ADD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const customizationDetailsReducer = (
  state = { customizationInfo: {}, loading: true },
  action
) => {
  switch (action.type) {
    case CUSTOMIZATION_DETAIL_REQUEST:
      return { ...state, loading: true };

    case CUSTOMIZATION_DETAIL_SUCCESS:
      return { loading: false, customizationInfo: action.payload };

    case CUSTOMIZATION_DETAIL_FAIL:
      return { loading: false, error: action.payload };

    case CUSTOMIZATION_DETAIL_RESET:
      return { customizationInfo: {} };

    default:
      return state;
  }
};

export const customizationUpdateReducer = (
  state = { customizationUpdateDetails: {} },
  action
) => {
  switch (action.type) {
    case CUSTOMIZATION_UPDATE_REQUEST:
      return { loading: true };

    case CUSTOMIZATION_UPDATE_SUCCESS:
      return {
        loading: false,
        updateSuccess: true,
        customizationUpdateDetails: action.payload,
      };

    case CUSTOMIZATION_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case CUSTOMIZATION_UPDATE_RESET:
      return { customizationUpdateDetails: [] };

    default:
      return state;
  }
};

export const customizationDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOMIZATION_DELETE_REQUEST:
      return { loading: true };

    case CUSTOMIZATION_DELETE_SUCCESS:
      return { loading: false, success: true };

    case CUSTOMIZATION_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
