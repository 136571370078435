import {
  CDEPARTMENT_LIST_REQUEST,
  CDEPARTMENT_LIST_SUCCESS,
  CDEPARTMENT_LIST_FAIL,
  CDEPARTMENT_LIST_RESET,
  CDEPARTMENT_DETAIL_REQUEST,
  CDEPARTMENT_DETAIL_SUCCESS,
  CDEPARTMENT_DETAIL_FAIL,
  CDEPARTMENT_DETAIL_RESET,
  NEW_CDEPARTMENT_ADD_REQUEST,
  NEW_CDEPARTMENT_ADD_SUCCESS,
  NEW_CDEPARTMENT_ADD_FAIL,
  CDEPARTMENT_UPDATE_REQUEST,
  CDEPARTMENT_UPDATE_SUCCESS,
  CDEPARTMENT_UPDATE_FAIL,
  CDEPARTMENT_UPDATE_RESET,
  CDEPARTMENT_DELETE_REQUEST,
  CDEPARTMENT_DELETE_SUCCESS,
  CDEPARTMENT_DELETE_FAIL,
  GST_LIST_REQUEST,
  GST_LIST_SUCCESS,
  GST_LIST_FAIL,
  GST_LIST_RESET,
  GST_DETAIL_REQUEST,
  GST_DETAIL_SUCCESS,
  GST_DETAIL_FAIL,
  GST_DETAIL_RESET,
  NEW_GST_ADD_REQUEST,
  NEW_GST_ADD_SUCCESS,
  NEW_GST_ADD_FAIL,
  GST_UPDATE_REQUEST,
  GST_UPDATE_SUCCESS,
  GST_UPDATE_FAIL,
  GST_UPDATE_RESET,
  GST_DELETE_REQUEST,
  GST_DELETE_SUCCESS,
  GST_DELETE_FAIL,
  COMPANY_LIST_REQUEST,
  COMPANY_LIST_SUCCESS,
  COMPANY_LIST_FAIL,
  COMPANY_LIST_RESET,
  COMPANY_DETAIL_REQUEST,
  COMPANY_DETAIL_SUCCESS,
  COMPANY_DETAIL_FAIL,
  COMPANY_DETAIL_RESET,
  NEW_COMPANY_ADD_REQUEST,
  NEW_COMPANY_ADD_SUCCESS,
  NEW_COMPANY_ADD_FAIL,
  COMPANY_UPDATE_REQUEST,
  COMPANY_UPDATE_SUCCESS,
  COMPANY_UPDATE_FAIL,
  COMPANY_UPDATE_RESET,
  COMPANY_DELETE_REQUEST,
  COMPANY_DELETE_SUCCESS,
  COMPANY_DELETE_FAIL,
} from "constants/companyConstants";

export const companyDepartmentListReducer = (
  state = { cdepartmentListDetails: [], loading: true },
  action
) => {
  switch (action.type) {
    case CDEPARTMENT_LIST_REQUEST:
      return { loading: true };

    case CDEPARTMENT_LIST_SUCCESS:
      return { loading: false, cdepartmentListDetails: action.payload };

    case CDEPARTMENT_LIST_FAIL:
      return { loading: false, error: action.payload };

    case CDEPARTMENT_LIST_RESET:
      return { cdepartmentListDetails: [], loading: true };

    default:
      return state;
  }
};

export const addNewCompanyDepartmentReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_CDEPARTMENT_ADD_REQUEST:
      return { loading: true };

    case NEW_CDEPARTMENT_ADD_SUCCESS:
      return { loading: false, newCompanyDepartmentAddInfo: action.payload };

    case NEW_CDEPARTMENT_ADD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const companyDepartmentDetailsReducer = (
  state = { companyDepartmentInfo: {}, loading: true },
  action
) => {
  switch (action.type) {
    case CDEPARTMENT_DETAIL_REQUEST:
      return { ...state, loading: true };

    case CDEPARTMENT_DETAIL_SUCCESS:
      return { loading: false, companyDepartmentInfo: action.payload };

    case CDEPARTMENT_DETAIL_FAIL:
      return { loading: false, error: action.payload };

    case CDEPARTMENT_DETAIL_RESET:
      return { companyDepartmentInfo: {} };

    default:
      return state;
  }
};

export const companyDepartmentUpdateReducer = (
  state = { companyDepartmentUpdateDetails: {} },
  action
) => {
  switch (action.type) {
    case CDEPARTMENT_UPDATE_REQUEST:
      return { loading: true };

    case CDEPARTMENT_UPDATE_SUCCESS:
      return {
        loading: false,
        updateSuccess: true,
        companyDepartmentUpdateDetails: action.payload,
      };

    case CDEPARTMENT_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case CDEPARTMENT_UPDATE_RESET:
      return { companyDepartmentUpdateDetails: [] };

    default:
      return state;
  }
};

export const companyDepartmentDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CDEPARTMENT_DELETE_REQUEST:
      return { loading: true };

    case CDEPARTMENT_DELETE_SUCCESS:
      return { loading: false, success: true };

    case CDEPARTMENT_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const companyGstListReducer = (
  state = { gstListDetails: [], loading: true },
  action
) => {
  switch (action.type) {
    case GST_LIST_REQUEST:
      return { loading: true };

    case GST_LIST_SUCCESS:
      return { loading: false, gstListDetails: action.payload };

    case GST_LIST_FAIL:
      return { loading: false, error: action.payload };

    case GST_LIST_RESET:
      return { gstListDetails: [], loading: true };

    default:
      return state;
  }
};

export const addNewCompanyGstReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_GST_ADD_REQUEST:
      return { loading: true };

    case NEW_GST_ADD_SUCCESS:
      return { loading: false, newGstAddInfo: action.payload };

    case NEW_GST_ADD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const companyGstDetailsReducer = (
  state = { gstInfo: {}, loading: true },
  action
) => {
  switch (action.type) {
    case GST_DETAIL_REQUEST:
      return { ...state, loading: true };

    case GST_DETAIL_SUCCESS:
      return { loading: false, gstInfo: action.payload };

    case GST_DETAIL_FAIL:
      return { loading: false, error: action.payload };

    case GST_DETAIL_RESET:
      return { gstInfo: {} };

    default:
      return state;
  }
};

export const companyGstUpdateReducer = (
  state = { gstUpdateDetails: {} },
  action
) => {
  switch (action.type) {
    case GST_UPDATE_REQUEST:
      return { loading: true };

    case GST_UPDATE_SUCCESS:
      return {
        loading: false,
        updateSuccess: true,
        gstUpdateDetails: action.payload,
      };

    case GST_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case GST_UPDATE_RESET:
      return { gstUpdateDetails: [] };

    default:
      return state;
  }
};

export const companyGstDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case GST_DELETE_REQUEST:
      return { loading: true };

    case GST_DELETE_SUCCESS:
      return { loading: false, success: true };

    case GST_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const companyListReducer = (
  state = { companyListDetails: [], loading: true },
  action
) => {
  switch (action.type) {
    case COMPANY_LIST_REQUEST:
      return { loading: true };

    case COMPANY_LIST_SUCCESS:
      return { loading: false, companyListDetails: action.payload };

    case COMPANY_LIST_FAIL:
      return { loading: false, error: action.payload };

    case COMPANY_LIST_RESET:
      return { companyListDetails: [], loading: true };

    default:
      return state;
  }
};

export const addNewCompanyReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_COMPANY_ADD_REQUEST:
      return { loading: true };

    case NEW_COMPANY_ADD_SUCCESS:
      return { loading: false, newCompanyAddInfo: action.payload };

    case NEW_COMPANY_ADD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const companyDetailsReducer = (
  state = { companyInfo: {}, loading: true },
  action
) => {
  switch (action.type) {
    case COMPANY_DETAIL_REQUEST:
      return { ...state, loading: true };

    case COMPANY_DETAIL_SUCCESS:
      return { loading: false, companyInfo: action.payload };

    case COMPANY_DETAIL_FAIL:
      return { loading: false, error: action.payload };

    case COMPANY_DETAIL_RESET:
      return { companyInfo: {} };

    default:
      return state;
  }
};

export const companyUpdateReducer = (
  state = { companyUpdateDetails: {} },
  action
) => {
  switch (action.type) {
    case COMPANY_UPDATE_REQUEST:
      return { loading: true };

    case COMPANY_UPDATE_SUCCESS:
      return {
        loading: false,
        updateSuccess: true,
        companyUpdateDetails: action.payload,
      };

    case COMPANY_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case COMPANY_UPDATE_RESET:
      return { companyUpdateDetails: [] };

    default:
      return state;
  }
};

export const companyDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPANY_DELETE_REQUEST:
      return { loading: true };

    case COMPANY_DELETE_SUCCESS:
      return { loading: false, success: true };

    case COMPANY_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
