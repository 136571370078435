import {
  UNIT_LIST_REQUEST,
  UNIT_LIST_SUCCESS,
  UNIT_LIST_FAIL,
  UNIT_LIST_RESET,
  UNIT_DETAIL_REQUEST,
  UNIT_DETAIL_SUCCESS,
  UNIT_DETAIL_FAIL,
  UNIT_DETAIL_RESET,
  NEW_UNIT_ADD_REQUEST,
  NEW_UNIT_ADD_SUCCESS,
  NEW_UNIT_ADD_FAIL,
  UNIT_UPDATE_REQUEST,
  UNIT_UPDATE_SUCCESS,
  UNIT_UPDATE_FAIL,
  UNIT_UPDATE_RESET,
  UNIT_DELETE_REQUEST,
  UNIT_DELETE_SUCCESS,
  UNIT_DELETE_FAIL,
} from "constants/catalogueConstants/unitConstants";

export const unitListReducer = (
  state = { unitListDetails: [], loading: true },
  action
) => {
  switch (action.type) {
    case UNIT_LIST_REQUEST:
      return { loading: true };

    case UNIT_LIST_SUCCESS:
      return { loading: false, unitListDetails: action.payload };

    case UNIT_LIST_FAIL:
      return { loading: false, error: action.payload };

    case UNIT_LIST_RESET:
      return { unitListDetails: [], loading: true };

    default:
      return state;
  }
};

export const addNewUnitReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_UNIT_ADD_REQUEST:
      return { loading: true };

    case NEW_UNIT_ADD_SUCCESS:
      return { loading: false, newUnitAddInfo: action.payload };

    case NEW_UNIT_ADD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const unitDetailsReducer = (
  state = { unitInfo: {}, loading: true },
  action
) => {
  switch (action.type) {
    case UNIT_DETAIL_REQUEST:
      return { ...state, loading: true };

    case UNIT_DETAIL_SUCCESS:
      return { loading: false, unitInfo: action.payload };

    case UNIT_DETAIL_FAIL:
      return { loading: false, error: action.payload };

    case UNIT_DETAIL_RESET:
      return { unitInfo: {} };

    default:
      return state;
  }
};

export const unitUpdateReducer = (
  state = { unitUpdateDetails: {} },
  action
) => {
  switch (action.type) {
    case UNIT_UPDATE_REQUEST:
      return { loading: true };

    case UNIT_UPDATE_SUCCESS:
      return {
        loading: false,
        updateSuccess: true,
        unitUpdateDetails: action.payload,
      };

    case UNIT_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case UNIT_UPDATE_RESET:
      return { unitUpdateDetails: [] };

    default:
      return state;
  }
};

export const unitDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case UNIT_DELETE_REQUEST:
      return { loading: true };

    case UNIT_DELETE_SUCCESS:
      return { loading: false, success: true };

    case UNIT_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
