export const DEPARTMENT_LIST_REQUEST = "DEPARTMENT_LIST_REQUEST";
export const DEPARTMENT_LIST_SUCCESS = "DEPARTMENT_LIST_SUCCESS";
export const DEPARTMENT_LIST_FAIL = "DEPARTMENT_LIST_FAIL";
export const DEPARTMENT_LIST_RESET = "DEPARTMENT_LIST_RESET";

export const DEPARTMENT_DETAIL_REQUEST = "DEPARTMENT_DETAIL_REQUEST";
export const DEPARTMENT_DETAIL_SUCCESS = "DEPARTMENT_DETAIL_SUCCESS";
export const DEPARTMENT_DETAIL_FAIL = "DEPARTMENT_DETAIL_FAIL";
export const DEPARTMENT_DETAIL_RESET = "DEPARTMENT_DETAIL_RESET";

export const NEW_DEPARTMENT_ADD_REQUEST = "NEW_DEPARTMENT_ADD_REQUEST";
export const NEW_DEPARTMENT_ADD_SUCCESS = "NEW_DEPARTMENT_ADD_SUCCESS";
export const NEW_DEPARTMENT_ADD_FAIL = "NEW_DEPARTMENT_ADD_FAIL";

export const DEPARTMENT_UPDATE_REQUEST = "DEPARTMENT_UPDATE_REQUEST";
export const DEPARTMENT_UPDATE_SUCCESS = "DEPARTMENT_UPDATE_SUCCESS";
export const DEPARTMENT_UPDATE_FAIL = "DEPARTMENT_UPDATE_FAIL";
export const DEPARTMENT_UPDATE_RESET = "DEPARTMENT_UPDATE_RESET";

export const DEPARTMENT_DELETE_REQUEST = "DEPARTMENT_DELETE_REQUEST";
export const DEPARTMENT_DELETE_SUCCESS = "DEPARTMENT_DELETE_SUCCESS";
export const DEPARTMENT_DELETE_FAIL = "DEPARTMENT_DELETE_FAIL";

export const SUPPLIER_LIST_REQUEST = "SUPPLIER_LIST_REQUEST";
export const SUPPLIER_LIST_SUCCESS = "SUPPLIER_LIST_SUCCESS";
export const SUPPLIER_LIST_FAIL = "SUPPLIER_LIST_FAIL";
export const SUPPLIER_LIST_RESET = "SUPPLIER_LIST_RESET";

export const SUPPLIER_DETAIL_REQUEST = "SUPPLIER_DETAIL_REQUEST";
export const SUPPLIER_DETAIL_SUCCESS = "SUPPLIER_DETAIL_SUCCESS";
export const SUPPLIER_DETAIL_FAIL = "SUPPLIER_DETAIL_FAIL";
export const SUPPLIER_DETAIL_RESET = "SUPPLIER_DETAIL_RESET";

export const NEW_SUPPLIER_ADD_REQUEST = "NEW_SUPPLIER_ADD_REQUEST";
export const NEW_SUPPLIER_ADD_SUCCESS = "NEW_SUPPLIER_ADD_SUCCESS";
export const NEW_SUPPLIER_ADD_FAIL = "NEW_SUPPLIER_ADD_FAIL";

export const SUPPLIER_UPDATE_REQUEST = "SUPPLIER_UPDATE_REQUEST";
export const SUPPLIER_UPDATE_SUCCESS = "SUPPLIER_UPDATE_SUCCESS";
export const SUPPLIER_UPDATE_FAIL = "SUPPLIER_UPDATE_FAIL";
export const SUPPLIER_UPDATE_RESET = "SUPPLIER_UPDATE_RESET";

export const SUPPLIER_DELETE_REQUEST = "SUPPLIER_DELETE_REQUEST";
export const SUPPLIER_DELETE_SUCCESS = "SUPPLIER_DELETE_SUCCESS";
export const SUPPLIER_DELETE_FAIL = "SUPPLIER_DELETE_FAIL";
