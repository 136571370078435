import { FiCamera, FiSearch } from "react-icons/fi";
import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { AuthContext } from "context/AuthContext";
import { developLog } from "utils";

// import { searchProducts } from "api/catlogue";

const Search = () => {
  const location = useLocation();
  const segments = location["pathname"].split("/");
  const [search, setSearch] = useState("");
  useEffect(() => {
    if (segments[1] === "product-search") {
      setSearch(segments[2]);
    } else {
      setSearch("");
    }
  }, [location]);

  const [foundSearch, setFoundSearch] = useState([]);
  const [show, setShow] = useState(false);
  const authPermissions = useContext(AuthContext);
  const isAdmin = authPermissions.user ? authPermissions.user.isAdmin : false;
  let history = useHistory();

  const bgColor = useColorModeValue("gray.200", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const filterByName = async (e) => {
    const keyword = e.target.value;
    // if (keyword !== "") {
    //   let results = await searchProducts(keyword, "");
    //   setFoundSearch(results);44*1.18
    //   setShow(true);
    // } else {
    //   setFoundSearch([]);
    //   setShow(false);
    // }
    setSearch(keyword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (search !== "") {
      // history.push(`/product-search/${search}`);
      window.location.href = `/product-search/${search}`;
    }
  };

  const handleCameraClick = async (e) => {
    // e.preventDefault();
    history.push(`/product-image-search/`);
  };
  const removeSearch = () => {
    setSearch("");
    setShow(false);
  };

  // return (
  //   <Flex direction={"row"} alignItems={"center"}>
  //     <Flex
  //       direction="column"
  //       // align="center"
  //       // position="relative"
  //       zIndex={1}
  //       // width="100%"
  //     >
  //       <form onSubmit={handleSubmit}>
  //         <InputGroup width="100%">
  //           <InputLeftElement
  //             pointerEvents="none"
  //             children={<FiSearch onClick={handleSubmit} />}
  //           />
  //           <Input
  //             type="text"
  //             value={search}
  //             placeholder="Search..."
  //             onChange={filterByName}
  //             borderRadius="md"
  //             _placeholder={{ color: "gray.500", opacity: 0.8 }}
  //             _focus={{ borderColor: "blue.400" }}
  //             width={{ base: "100%", md: "100%", lg: "65rem" }}
  //           />
  //         </InputGroup>
  //       </form>

  //       {/* {show && (
  //       <Box
  //         position="absolute"
  //         top="100%"
  //         width={{ base: "100%", md: "100%", lg: "50rem" }}
  //         zIndex={2}
  //         bg={bgColor}
  //       >
  //         {foundSearch.count > 0 &&
  //           foundSearch.results.map((searchItem) => (
  //             <Link
  //               onClick={removeSearch}
  //               href={
  //                 isAdmin
  //                   ? `/admin/product-details/${searchItem.slug}/${searchItem.child_model_variant[0].slug}`
  //                   : `/catalogue/all-products/product-detail/${searchItem.slug}/${searchItem.child_model_variant[0].slug}`
  //               }
  //               key={searchItem.id}
  //               display="flex"
  //               alignItems="center"
  //               p={2}
  //               borderBottom="1px solid"
  //               borderColor={borderColor}
  //             >
  //               <Image
  //                 src={
  //                   searchItem.child_model_variant[0].v_product_image[0].v_image
  //                 }
  //                 alt=""
  //                 boxSize="2rem"
  //                 mr={2}
  //               />
  //               <Text>{searchItem.title}</Text>
  //             </Link>
  //           ))}
  //       </Box>
  //     )} */}
  //     </Flex>

  //     <FiCamera onClick={handleCameraClick} size={40} p={4} ml={4} />
  //   </Flex>
  // );
  return (
    <Flex direction="row" alignItems="center">
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          children={
            <FiSearch
              color="gray.400"
              onClick={handleSubmit}
              cursor="pointer"
            />
          }
        />

        <Input
          type="text"
          value={search}
          placeholder="Search..."
          onChange={filterByName}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSubmit(e);
            }
          }}
          // onKeyDown={handleSubmit}
          borderRadius="md"
          _placeholder={{ color: "gray.500", opacity: 0.8 }}
          _focus={{ borderColor: "blue.400" }}
        />

        <InputRightElement>
          <FiCamera
            onClick={handleCameraClick}
            size={24}
            color="gray.400"
            cursor="pointer"
          />
        </InputRightElement>
      </InputGroup>
    </Flex>
  );
};

export default Search;
