export const CDEPARTMENT_LIST_REQUEST = "CDEPARTMENT_LIST_REQUEST";
export const CDEPARTMENT_LIST_SUCCESS = "CDEPARTMENT_LIST_SUCCESS";
export const CDEPARTMENT_LIST_FAIL = "CDEPARTMENT_LIST_FAIL";
export const CDEPARTMENT_LIST_RESET = "CDEPARTMENT_LIST_RESET";

export const CDEPARTMENT_DETAIL_REQUEST = "CDEPARTMENT_DETAIL_REQUEST";
export const CDEPARTMENT_DETAIL_SUCCESS = "CDEPARTMENT_DETAIL_SUCCESS";
export const CDEPARTMENT_DETAIL_FAIL = "CDEPARTMENT_DETAIL_FAIL";
export const CDEPARTMENT_DETAIL_RESET = "CDEPARTMENT_DETAIL_RESET";

export const NEW_CDEPARTMENT_ADD_REQUEST = "NEW_CDEPARTMENT_ADD_REQUEST";
export const NEW_CDEPARTMENT_ADD_SUCCESS = "NEW_CDEPARTMENT_ADD_SUCCESS";
export const NEW_CDEPARTMENT_ADD_FAIL = "NEW_CDEPARTMENT_ADD_FAIL";

export const CDEPARTMENT_UPDATE_REQUEST = "CDEPARTMENT_UPDATE_REQUEST";
export const CDEPARTMENT_UPDATE_SUCCESS = "CDEPARTMENT_UPDATE_SUCCESS";
export const CDEPARTMENT_UPDATE_FAIL = "CDEPARTMENT_UPDATE_FAIL";
export const CDEPARTMENT_UPDATE_RESET = "CDEPARTMENT_UPDATE_RESET";

export const CDEPARTMENT_DELETE_REQUEST = "CDEPARTMENT_DELETE_REQUEST";
export const CDEPARTMENT_DELETE_SUCCESS = "CDEPARTMENT_DELETE_SUCCESS";
export const CDEPARTMENT_DELETE_FAIL = "CDEPARTMENT_DELETE_FAIL";

export const GST_LIST_REQUEST = "GST_LIST_REQUEST";
export const GST_LIST_SUCCESS = "GST_LIST_SUCCESS";
export const GST_LIST_FAIL = "GST_LIST_FAIL";
export const GST_LIST_RESET = "GST_LIST_RESET";

export const GST_DETAIL_REQUEST = "GST_DETAIL_REQUEST";
export const GST_DETAIL_SUCCESS = "GST_DETAIL_SUCCESS";
export const GST_DETAIL_FAIL = "GST_DETAIL_FAIL";
export const GST_DETAIL_RESET = "GST_DETAIL_RESET";

export const NEW_GST_ADD_REQUEST = "NEW_GST_ADD_REQUEST";
export const NEW_GST_ADD_SUCCESS = "NEW_GST_ADD_SUCCESS";
export const NEW_GST_ADD_FAIL = "NEW_GST_ADD_FAIL";

export const GST_UPDATE_REQUEST = "GST_UPDATE_REQUEST";
export const GST_UPDATE_SUCCESS = "GST_UPDATE_SUCCESS";
export const GST_UPDATE_FAIL = "GST_UPDATE_FAIL";
export const GST_UPDATE_RESET = "GST_UPDATE_RESET";

export const GST_DELETE_REQUEST = "GST_DELETE_REQUEST";
export const GST_DELETE_SUCCESS = "GST_DELETE_SUCCESS";
export const GST_DELETE_FAIL = "GST_DELETE_FAIL";

export const COMPANY_LIST_REQUEST = "COMPANY_LIST_REQUEST";
export const COMPANY_LIST_SUCCESS = "COMPANY_LIST_SUCCESS";
export const COMPANY_LIST_FAIL = "COMPANY_LIST_FAIL";
export const COMPANY_LIST_RESET = "COMPANY_LIST_RESET";

export const COMPANY_DETAIL_REQUEST = "COMPANY_DETAIL_REQUEST";
export const COMPANY_DETAIL_SUCCESS = "COMPANY_DETAIL_SUCCESS";
export const COMPANY_DETAIL_FAIL = "COMPANY_DETAIL_FAIL";
export const COMPANY_DETAIL_RESET = "COMPANY_DETAIL_RESET";

export const NEW_COMPANY_ADD_REQUEST = "NEW_COMPANY_ADD_REQUEST";
export const NEW_COMPANY_ADD_SUCCESS = "NEW_COMPANY_ADD_SUCCESS";
export const NEW_COMPANY_ADD_FAIL = "NEW_COMPANY_ADD_FAIL";

export const COMPANY_UPDATE_REQUEST = "COMPANY_UPDATE_REQUEST";
export const COMPANY_UPDATE_SUCCESS = "COMPANY_UPDATE_SUCCESS";
export const COMPANY_UPDATE_FAIL = "COMPANY_UPDATE_FAIL";
export const COMPANY_UPDATE_RESET = "COMPANY_UPDATE_RESET";

export const COMPANY_DELETE_REQUEST = "COMPANY_DELETE_REQUEST";
export const COMPANY_DELETE_SUCCESS = "COMPANY_DELETE_SUCCESS";
export const COMPANY_DELETE_FAIL = "COMPANY_DELETE_FAIL";
