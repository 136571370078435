import { Redirect, Route } from "react-router-dom";

import React from "react";
import { connect } from "react-redux";

const PrivateRoute = ({ component: Component, user, ...rest }) => {
  const requestedPath = window.location.pathname;
  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location.pathname },
            }}
          />
          // <Redirect to={`/login?redirect=${requestedPath}`} />
        )
      }
    />
  );
};

function mapStateToProps(state) {
  return {
    user: state.userLogin.userLoginInfo,
  };
}

export default connect(mapStateToProps)(PrivateRoute);
