import {
  Box,
  Flex,
  HStack,
  IconButton,
  Image,
  Link,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaInstagram, FaLinkedin } from "react-icons/fa";

import React from "react";

const Footer = () => {
  const bgColor = useColorModeValue("gray.200", "gray.700");
  const textColor = useColorModeValue("gray.800", "white");
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <Box as="footer" py="2rem" bg={bgColor} color={textColor} mt={2}>
      <Flex
        align="center"
        justify="center"
        direction="column"
        px={{ base: "1rem", md: "0" }}
      >
        <Box mb="1rem">
          <Flex align="center" justify="center" mb="1rem">
            <Image
              src={
                colorMode === "dark"
                  ? "/images/footer/logo_color.png"
                  : "/images/footer/logo_black.png"
              }
              alt="Company Logo"
              maxH="8rem"
              width="auto"
              borderColor={textColor}
              borderWidth="0.0625rem"
              borderRadius="0.125rem"
              p="0.0625rem"
            />
          </Flex>
          <HStack spacing="0.4rem" justify="center" mb="1rem">
            <Link href="/">Home</Link>
            <Link href="/about">About Us</Link>
            <Link href="/terms-conditions">Terms and Conditions</Link>
            <Link href="/corporate-enquiry">Explore Corporate Features</Link>
          </HStack>
          <Flex align="center" justify="center">
            <HStack>
              <IconButton
                as={Link}
                href="https://www.instagram.com/sonalenterprises_gifts/"
                aria-label="Instagram"
                icon={<FaInstagram size={24} />}
                size="4rem"
                variant="ghost"
              />
              <IconButton
                as={Link}
                href="https://www.linkedin.com/company/sonal-enterprises-corporate-gift-shop"
                aria-label="LinkedIn"
                icon={<FaLinkedin size={24} />}
                size="4rem"
                variant="ghost"
              />
            </HStack>
          </Flex>
        </Box>
        <Box fontSize={{ base: "0.875rem", md: "1rem" }} textAlign="center">
          © {new Date().getFullYear()} Sonal Enterprises. All rights reserved.
        </Box>
      </Flex>
    </Box>
  );
};

export default Footer;
