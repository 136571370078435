import {
  deleteData,
  developLog,
  getData,
  getPublicData,
  postData,
  postDataWithForm,
  postPublicDataWithForm,
  putData,
} from "utils";

// Admin APIS
export async function getAdminProductList(url = "") {
  // Admin product list
  try {
    let apiUrl = `/catalogue/admin/?page_size=30`;
    if (url.length !== 0) {
      apiUrl = url;
    }
    const response = await getData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in getAdminProductList:", error);
  }
}

export async function getAdminProductDetails(slug = "") {
  // Admin product details
  try {
    if (slug === "") {
      alert("Select valid slug");
      return null;
    }
    let apiUrl = `/catalogue/admin/${slug}`;
    const response = await getData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in getAdminProductDetails:", error);
  }
}
export async function getAllUnits() {
  try {
    let apiUrl = `/catalogue/Units`;
    let response = await getData(apiUrl);
    if (response.hasOwnProperty("data")) {
      response = response.data;
      let units = response.results;
      while (response.links.next) {
        response = await getData(response.links.next);
        if (response.hasOwnProperty("data")) {
          response = response.data;
          units.push(...response.results);
        }
      }
      return units;
    }
  } catch (error) {
    developLog("Error in getAllUnits:", error);
  }
}
export async function getUnitsPage(url = "") {
  try {
    let apiUrl = `/catalogue/Units`;
    if (url.length !== 0) {
      apiUrl = url;
    }
    const response = await getData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in getUnitsPage:", error);
  }
}
export async function createUnits(payload) {
  try {
    let apiUrl = `/catalogue/Units`;
    let response = await postData(apiUrl, payload);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in createUnits:", error);
  }
}
export async function getUnits(id = "") {
  try {
    if (id === "") {
      alert("Select valid id");
      return null;
    }
    let apiUrl = `/catalogue/Unit/${id}`;
    const response = await getData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in getUnits:", error);
  }
}
export async function updateUnits(id = "", payload) {
  try {
    if (id === "") {
      alert("Select valid id");
      return null;
    }
    let apiUrl = `/catalogue/Unit/${id}`;
    const response = await putData(apiUrl, payload);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in updateUnits:", error);
  }
}
export async function deleteUnits(id = "") {
  try {
    if (id === "") {
      alert("Select valid id");
      return null;
    }
    let apiUrl = `/catalogue/Unit/${id}`;
    const response = await deleteData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in deleteUnits:", error);
  }
}
export async function getAllUQC() {
  try {
    let apiUrl = `/catalogue/UQC`;
    let response = await getData(apiUrl);
    if (response.hasOwnProperty("data")) {
      response = response.data;
      let uqc = response.results;
      while (response.links.next) {
        response = await getData(response.links.next);
        if (response.hasOwnProperty("data")) {
          response = response.data;
          uqc.push(...response.results);
        }
      }
      return uqc;
    }
  } catch (error) {
    developLog("Error in getAllUQC:", error);
  }
}
export async function getUQCPage(url = "") {
  try {
    let apiUrl = `/catalogue/UQC`;
    if (url.length !== 0) {
      apiUrl = url;
    }
    const response = await getData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in getUQCPage:", error);
  }
}
export async function createUQC(payload) {
  try {
    let apiUrl = `/catalogue/UQC`;
    let response = await postData(apiUrl, payload);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in createUQC:", error);
  }
}
export async function getUQC(id = "") {
  try {
    if (id === "") {
      alert("Select valid id");
      return null;
    }
    let apiUrl = `/catalogue/UQC/${id}`;
    const response = await getData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in getUQC:", error);
  }
}
export async function updateUQC(id = "", payload) {
  try {
    if (id === "") {
      alert("Select valid id");
      return null;
    }
    let apiUrl = `/catalogue/UQC/${id}`;
    const response = await putData(apiUrl, payload);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in updateUQC:", error);
  }
}
export async function deleteUQC(id = "") {
  try {
    if (id === "") {
      alert("Select valid id");
      return null;
    }
    let apiUrl = `/catalogue/UQC/${id}`;
    const response = await deleteData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in deleteUQC:", error);
  }
}

export async function getAllProductTypes() {
  try {
    let apiUrl = `/catalogue/product_types`;
    let response = await getData(apiUrl);
    if (response.hasOwnProperty("data")) {
      response = response.data;
      let product_types = response.results;
      while (response.links.next) {
        response = await getData(response.links.next);
        if (response.hasOwnProperty("data")) {
          response = response.data;
          product_types.push(...response.results);
        }
      }
      return [...new Set(product_types)];
    }
  } catch (error) {
    developLog("Error in getAllProductTypes:", error);
  }
}
export async function getProductTypePage(url = "") {
  try {
    let apiUrl = `/catalogue/product_types`;
    if (url.length !== 0) {
      apiUrl = url;
    }
    const response = await getData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in getProductTypePage:", error);
  }
}
export async function createProductType(payload) {
  try {
    let apiUrl = `/catalogue/product_types`;
    let response = await postData(apiUrl, payload);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in createProductType:", error);
  }
}
export async function getProductType(id = "") {
  try {
    if (id === "") {
      alert("Select valid id");
      return null;
    }
    let apiUrl = `/catalogue/product_type/${id}`;
    const response = await getData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in getProductType:", error);
  }
}
export async function updateProductType(id = "", payload) {
  try {
    if (id === "") {
      alert("Select valid id");
      return null;
    }
    let apiUrl = `/catalogue/product_type/${id}`;
    const response = await putData(apiUrl, payload);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in updateProductType:", error);
  }
}
export async function deleteProductType(id = "") {
  try {
    if (id === "") {
      alert("Select valid id");
      return null;
    }
    let apiUrl = `/catalogue/product_type/${id}`;
    const response = await deleteData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in deleteProductType:", error);
  }
}
export async function getAllCategories() {
  try {
    let apiUrl = `/catalogue/categories`;
    let response = await getData(apiUrl);
    if (response.hasOwnProperty("data")) {
      response = response.data;
      let categories = response.results;
      while (response.links.next) {
        response = await getData(response.links.next);
        if (response.hasOwnProperty("data")) {
          response = response.data;
          categories.push(...response.results);
        }
      }
      return categories;
    }
  } catch (error) {
    developLog("Error in getAllCategories:", error);
  }
}
export async function getCategoryPage(url = "") {
  try {
    let apiUrl = `/catalogue/categories`;
    if (url.length !== 0) {
      apiUrl = url;
    }
    const response = await getData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in getCategoryPage:", error);
  }
}
export async function createCategory(payload) {
  try {
    let apiUrl = `/catalogue/categories`;
    let response = await postData(apiUrl, payload);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in createCategory:", error);
  }
}
export async function getCategory(slug = "") {
  try {
    if (slug === "") {
      alert("Select valid slug");
      return null;
    }
    let apiUrl = `/catalogue/category/${slug}`;
    const response = await getData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in getCategory:", error);
  }
}
export async function updateCategory(slug = "", payload) {
  try {
    if (slug === "") {
      alert("Select valid slug");
      return null;
    }
    let apiUrl = `/catalogue/category/${slug}`;
    const response = await putData(apiUrl, payload);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in updateCategory:", error);
  }
}
export async function deleteCategory(slug = "") {
  try {
    if (slug === "") {
      alert("Select valid slug");
      return null;
    }
    let apiUrl = `/catalogue/category/${slug}`;
    const response = await deleteData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in deleteCategory:", error);
  }
}
export async function getAllCustomizations() {
  try {
    let apiUrl = `/catalogue/customizations`;
    let response = await getData(apiUrl);
    if (response.hasOwnProperty("data")) {
      response = response.data;
      let customizations = response.results;
      while (response.links.next) {
        response = await getData(response.links.next);
        if (response.hasOwnProperty("data")) {
          response = response.data;
          customizations.push(...response.results);
        }
      }
      return customizations;
    }
  } catch (error) {
    developLog("Error in getAllCustomizations:", error);
  }
}
export async function getCustomizationPage(url = "") {
  try {
    let apiUrl = `/catalogue/customizations`;
    if (url.length !== 0) {
      apiUrl = url;
    }
    const response = await getData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in getCustomizationPage:", error);
  }
}
export async function createCustomization(payload) {
  try {
    let apiUrl = `/catalogue/customizations`;
    let response = await postData(apiUrl, payload);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in createCustomization:", error);
  }
}
export async function getCustomization(id = "") {
  try {
    if (id === "") {
      alert("Select valid id");
      return null;
    }
    let apiUrl = `/catalogue/customizations/${id}`;
    const response = await getData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in getCustomization:", error);
  }
}
export async function updateCustomization(id = "", payload) {
  try {
    if (id === "") {
      alert("Select valid id");
      return null;
    }
    let apiUrl = `/catalogue/customizations/${id}`;
    const response = await putData(apiUrl, payload);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in updateCustomization:", error);
  }
}
export async function deleteCustomization(id = "") {
  try {
    if (id === "") {
      alert("Select valid id");
      return null;
    }
    let apiUrl = `/catalogue/customizations/${id}`;
    const response = await deleteData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in deleteCustomization:", error);
  }
}
export async function createProduct(payload) {
  try {
    let apiUrl = `/catalogue/admin/`;
    let response = await postData(apiUrl, payload);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in createProduct:", error);
  }
}

export async function createVariant(payload) {
  try {
    let apiUrl = `/catalogue/admin_v/`;
    let response = await postData(apiUrl, payload);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in createVariant:", error);
  }
}

export async function uploadVairantImage(formData, variant_slug) {
  try {
    let apiUrl = `/catalogue/upload_variant_images/${variant_slug}`;
    let response = await postDataWithForm(apiUrl, formData);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in uploadVairantImage:", error);
  }
}

export async function updateProduct(product_slug, payload) {
  try {
    if (product_slug === "") {
      alert("Select valid product_slug");
      return null;
    }
    let apiUrl = `/catalogue/admin/${product_slug}`;
    const response = await putData(apiUrl, payload);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in updateProduct:", error);
  }
}
export async function deleteProduct(product_slug = "") {
  try {
    if (product_slug === "") {
      alert("Select valid product_slug");
      return null;
    }
    let apiUrl = `/catalogue/admin/${product_slug}`;
    const response = await deleteData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in deleteProduct:", error);
  }
}
export async function updateVariant(variant_slug, payload) {
  try {
    if (variant_slug === "") {
      alert("Select valid variant_slug");
      return null;
    }
    let apiUrl = `/catalogue/admin_v/${variant_slug}/`;
    const response = await putData(apiUrl, payload);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in updateVariant:", error);
  }
}

// User APIS

// Public APIS
export async function getProductList(
  url = "",
  min_price = null,
  max_price = null,
  brands = null
) {
  // User product list
  try {
    let apiUrl = `/catalogue/b2b-products/?page_size=30`;
    if (url.length !== 0) {
      apiUrl = url;
    }
    if (min_price !== null && max_price !== null) {
      apiUrl = apiUrl + `&min_price=${min_price}`;
      apiUrl = apiUrl + `&max_price=${max_price}`;
    }

    if (brands !== null && brands.length > 0) {
      brands.forEach((brand) => {
        apiUrl = apiUrl + `&brand=${brand}`;
      });
    }
    const response = await getPublicData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in getProductList:", error);
  }
}

export async function searchProducts(
  keyword = "",
  url = "",
  min_price = null,
  max_price = null,
  brands = null
) {
  // User product list
  try {
    if (keyword === "") {
      alert("Select valid keyword");
      return null;
    }
    let apiUrl = `/catalogue/b2b-products/?search=${keyword}&page_size=30`;
    if (url.length !== 0) {
      apiUrl = url;
    }
    if (min_price !== null && max_price !== null) {
      apiUrl = apiUrl + `&min_price=${min_price}`;
      apiUrl = apiUrl + `&max_price=${max_price}`;
    }
    if (brands !== null && brands.length > 0) {
      brands.forEach((brand) => {
        apiUrl = apiUrl + `&brand=${brand}`;
      });
    }

    const response = await getPublicData(apiUrl);
    developLog("searchProducts response:", response);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in searchProducts:", error);
  }
}

export async function searchAdminProducts(
  keyword = "",
  url = "",
  min_price = null,
  max_price = null,
  brands = null
) {
  // User product list
  try {
    if (keyword === "") {
      alert("Select valid keyword");
      return null;
    }
    let apiUrl = `/catalogue/admin/?search=${keyword}&page_size=30`;
    if (url.length !== 0) {
      apiUrl = url;
    }
    if (min_price !== null && max_price !== null) {
      apiUrl = apiUrl + `&min_price=${min_price}`;
      apiUrl = apiUrl + `&max_price=${max_price}`;
    }
    if (brands !== null && brands.length > 0) {
      brands.forEach((brand) => {
        apiUrl = apiUrl + `&brand=${brand}`;
      });
    }
    const response = await getPublicData(apiUrl);
    developLog("searchAdminProducts response:", response);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in searchAdminProducts:", error);
  }
}

export async function productsBySupplier(
  url = "",
  min_price = null,
  max_price = null,
  supplier_code = 1
) {
  // User product list
  try {
    let apiUrl = `/catalogue/admin/?child_model_variant__suppliers=${supplier_code}&page_size=30`;
    if (url.length !== 0) {
      apiUrl = url;
    }
    if (min_price !== null && max_price !== null) {
      apiUrl = apiUrl + `&min_price=${min_price}`;
      apiUrl = apiUrl + `&max_price=${max_price}`;
    }

    const response = await getData(apiUrl);
    developLog("productsBySupplier response:", response);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in productsBySupplier:", error);
  }
}
export async function getProductDetails(slug = "") {
  // User product details
  try {
    if (slug === "") {
      alert("Select valid slug");
      return null;
    }
    let apiUrl = `/catalogue/product-details/${slug}`;
    const response = await getPublicData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in getProductDetails:", error);
  }
}

export async function getUserCategories() {
  try {
    let apiUrl = `/catalogue/product-categories`;
    let response = await getPublicData(apiUrl);
    if (response.hasOwnProperty("data")) {
      response = response.data;
      let categories = response.results;
      while (response.links.next) {
        response = await getPublicData(response.links.next);
        response = response.data;
        categories.push(...response.results);
      }
      return categories;
    }
  } catch (error) {
    developLog("Error in getUserCategories:", error);
  }
}
export async function getUserParentCategories() {
  try {
    let apiUrl = `/catalogue/product-categories`;
    let response = await getPublicData(apiUrl);
    if (response.hasOwnProperty("data")) {
      response = response.data;
      let categories = [];
      let results = response.results;
      let parent_cateogories = results.filter(
        (category) => category.parent == null && category.is_active === true
      );
      categories.push(...parent_cateogories);
      while (response.links.next) {
        response = await getPublicData(response.links.next);
        response = response.data;
        let results = response.results;
        let parent_cateogories = results.filter(
          (category) => category.parent == null
        );
        categories.push(...parent_cateogories);
      }
      const set = new Set(categories);

      return Array.from(set);
    }
  } catch (error) {
    developLog("Error in getUserParentCategories:", error);
  }
}
export async function getUserCategoryDetails(slug = "") {
  try {
    if (slug === "") {
      alert("Select valid slug");
      return null;
    }
    let apiUrl = `/catalogue/product-categories/${slug}`;
    const response = await getPublicData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in getUserCategoryDetails:", error);
  }
}

export async function getProductsByCategory(
  url = "",
  category_slug = "",
  min_price = null,
  max_price = null,
  brands = null
) {
  try {
    if (category_slug === "") {
      alert("Select valid slug");
      return null;
    }
    let apiUrl = `/catalogue/b2b-products/?categories=${category_slug}&page_size=30`;
    if (url.length !== 0) {
      apiUrl = url;
    }
    if (min_price !== null && max_price !== null) {
      apiUrl = apiUrl + `&min_price=${min_price}`;
      apiUrl = apiUrl + `&max_price=${max_price}`;
    }
    if (brands !== null && brands.length > 0) {
      brands.forEach((brand) => {
        apiUrl = apiUrl + `&brand=${brand}`;
      });
    }
    const response = await getPublicData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in getProductsByCategory:", error);
  }
}

export async function getProductsByBrand(
  url = "",
  min_price = null,
  max_price = null,
  brand = null
) {
  try {
    let apiUrl = `/catalogue/b2b-products/?brand=${brand}&page_size=30`;
    if (url.length !== 0) {
      apiUrl = url;
    }
    if (min_price !== null && max_price !== null) {
      apiUrl = apiUrl + `&min_price=${min_price}`;
      apiUrl = apiUrl + `&max_price=${max_price}`;
    }

    const response = await getPublicData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in getProductsByBrand:", error);
  }
}

export async function getBrandDetails(slug = "") {
  // User product details
  try {
    if (slug === "") {
      alert("Select valid slug");
      return null;
    }
    let apiUrl = `/catalogue/brands/${slug}`;
    const response = await getPublicData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in getBrandDetails:", error);
  }
}

export async function getBanners() {
  try {
    let apiUrl = `/catalogue/banners`;
    const response = await getPublicData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in getBanners:", error);
  }
}

export async function getB2BBanners() {
  try {
    let apiUrl = `/catalogue/b2bbanners`;
    const response = await getPublicData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in getB2BBanners:", error);
  }
}
export async function getSections() {
  try {
    let apiUrl = `/catalogue/sections`;
    const response = await getPublicData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in getSections:", error);
  }
}

export async function getVariantReviews(slug, url) {
  try {
    let apiUrl = `/catalogue/product-reviews/${slug}`;
    if (url.length !== 0) {
      apiUrl = url;
    }
    const response = await getPublicData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in getVariantReviews:", error);
  }
}

export async function searchProductsByImage(payload, url = null) {
  try {
    let apiUrl = `/catalogue/b2b-image-search/?page_size=30`;
    if (url.length !== 0) {
      apiUrl = url;
    }
    const response = await postPublicDataWithForm(apiUrl, payload);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in searchProductsByImage:", error);
  }
}

export async function getUserBrands() {
  try {
    let apiUrl = `/catalogue/brands`;
    let response = await getPublicData(apiUrl);
    if (response.hasOwnProperty("data")) {
      response = response.data;
      let brands = response.results;
      while (response.links.next) {
        response = await getPublicData(response.links.next);
        response = response.data;
        brands.push(...response.results);
      }
      return brands;
    }
  } catch (error) {
    developLog("Error in getUserBrands:", error);
  }
}
