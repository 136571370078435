import {
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAIL,
  CATEGORY_LIST_RESET,
  CATEGORY_DETAIL_REQUEST,
  CATEGORY_DETAIL_SUCCESS,
  CATEGORY_DETAIL_FAIL,
  CATEGORY_DETAIL_RESET,
  NEW_CATEGORY_ADD_REQUEST,
  NEW_CATEGORY_ADD_SUCCESS,
  NEW_CATEGORY_ADD_FAIL,
  CATEGORY_UPDATE_REQUEST,
  CATEGORY_UPDATE_SUCCESS,
  CATEGORY_UPDATE_FAIL,
  CATEGORY_UPDATE_RESET,
  CATEGORY_DELETE_REQUEST,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_DELETE_FAIL,
} from "constants/catalogueConstants/categoryConstants";

export const categoryListReducer = (
  state = { categoryListDetails: [], loading: true },
  action
) => {
  switch (action.type) {
    case CATEGORY_LIST_REQUEST:
      return { loading: true };

    case CATEGORY_LIST_SUCCESS:
      return { loading: false, categoryListDetails: action.payload };

    case CATEGORY_LIST_FAIL:
      return { loading: false, error: action.payload };

    case CATEGORY_LIST_RESET:
      return { categoryListDetails: [], loading: true };

    default:
      return state;
  }
};

export const addNewCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_CATEGORY_ADD_REQUEST:
      return { loading: true };

    case NEW_CATEGORY_ADD_SUCCESS:
      return { loading: false, newCategoryAddInfo: action.payload };

    case NEW_CATEGORY_ADD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const categoryDetailsReducer = (
  state = { categoryInfo: {}, loading: true },
  action
) => {
  switch (action.type) {
    case CATEGORY_DETAIL_REQUEST:
      return { ...state, loading: true };

    case CATEGORY_DETAIL_SUCCESS:
      return { loading: false, categoryInfo: action.payload };

    case CATEGORY_DETAIL_FAIL:
      return { loading: false, error: action.payload };

    case CATEGORY_DETAIL_RESET:
      return { categoryInfo: {} };

    default:
      return state;
  }
};

export const categoryUpdateReducer = (
  state = { categoryUpdateDetails: {} },
  action
) => {
  switch (action.type) {
    case CATEGORY_UPDATE_REQUEST:
      return { loading: true };

    case CATEGORY_UPDATE_SUCCESS:
      return {
        loading: false,
        updateSuccess: true,
        categoryUpdateDetails: action.payload,
      };

    case CATEGORY_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case CATEGORY_UPDATE_RESET:
      return { categoryUpdateDetails: [] };

    default:
      return state;
  }
};

export const categoryDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_DELETE_REQUEST:
      return { loading: true };

    case CATEGORY_DELETE_SUCCESS:
      return { loading: false, success: true };

    case CATEGORY_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
