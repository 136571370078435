import {
  deleteData,
  developLog,
  getData,
  postData,
  postDataWithForm,
  postPublicData,
  putData,
  putDataWithForm,
} from "utils";

export async function registerDevice(payload) {
  try {
    let apiUrl = `/devices`;
    const response = await postData(apiUrl, payload);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in registerDevice:", error);
  }
}
export async function getUserDetails(user_id = "") {
  try {
    if (user_id === "") {
      alert("Select valid user");
      return null;
    }
    let apiUrl = `/users/user-detail/${user_id}`;
    const response = await getData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in getUserDetails:", error);
  }
}
export async function removeUser(user_id = "") {
  try {
    if (user_id === "") {
      alert("Select valid user");
      return null;
    }
    let apiUrl = `/users/user-detail/${user_id}`;
    const response = await deleteData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in removeUser:", error);
  }
}
export async function createUser(payload) {
  try {
    let apiUrl = `/users/user-list`;
    const response = await postDataWithForm(apiUrl, payload);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in createUser:", error);
  }
}
export async function updateUserDetails(user_id, payload) {
  try {
    if (user_id === "") {
      alert("Select valid user");
      return null;
    }
    let apiUrl = `/users/user-detail/${user_id}`;
    const response = await putDataWithForm(apiUrl, payload);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in updateUserDetails:", error);
  }
}
export async function getAddressList() {
  try {
    let apiUrl = `/users/address/`;
    let response = await getData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
    // response = response.data
    // let address_list = response.results;
    // let page_count = 2
    // while (response.links.next) {
    //     apiUrl = apiUrl + `?page=${page_count}`;
    //     response = await getData(apiUrl)
    //     response = response.data
    //     page_count = page_count + 1;
    //     address_list.push(...response.results);
    // }
    // return address_list;
  } catch (error) {
    developLog("Error in getAddressList:", error);
  }
}

export async function createAddress(payload) {
  try {
    let apiUrl = `/users/address/`;
    let response = await postData(apiUrl, payload);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in createAddress:", error);
    return error;
  }
}
export async function getAddress(address_id) {
  try {
    if (address_id === "") {
      alert("Select valid address");
      return null;
    }
    let apiUrl = `/users/address/${address_id}`;
    const response = await getData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in address_id:", error);
  }
}
export async function updateAddress(address_id, payload) {
  try {
    if (address_id === "") {
      alert("Select valid address");
      return null;
    }
    let apiUrl = `/users/address/${address_id}`;
    const response = await putData(apiUrl, payload);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in address_id:", error);
  }
}

export async function getUserList() {
  try {
    let apiUrl = `/users/user-list`;
    let response = await getData(apiUrl);
    response = response.data;
    let users = response.results;
    while (response.links.next) {
      response = await getData(response.links.next);
      response = response.data;
      users.push(...response.results);
    }
    return users;
  } catch (error) {
    developLog("Error in getUserList:", error);
  }
}
export async function getUserPage(url = "") {
  try {
    let apiUrl = `/users/user-list`;
    if (url.length != 0) {
      apiUrl = url;
    }
    const response = await getData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in getUserPage:", error);
  }
}

export async function confirmEmail(payload) {
  try {
    let apiUrl = `/users/account-confirm-email/`;
    let response = await postPublicData(apiUrl, payload);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in confirmEmail:", error);
    return error;
  }
}

export async function updatePassword(payload) {
  try {
    let apiUrl = `/users/password/change/`;
    let response = await postData(apiUrl, payload);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in updatePassword:", error);
    return error;
  }
}

export async function forgotPassword(payload) {
  try {
    let apiUrl = `/users/password-reset/`;
    let response = await postPublicData(apiUrl, payload);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in forgotPassword:", error);
    return error;
  }
}

export async function passwordResetConfirm(uid, token, payload) {
  try {
    let apiUrl = `/users/password-reset-confirm/${uid}/${token}/`;
    let response = await postPublicData(apiUrl, payload);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in passwordResetConfirm:", error);
    return error;
  }
}

export async function getAuthToken(payload) {
  try {
    let apiUrl = `/auth/token/`;
    let response = await postPublicData(apiUrl, payload);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in getAuthToken:", error);
    return error;
  }
}

export async function authenticate(payload) {
  try {
    let apiUrl = `/users/login/`;
    let response = await postPublicData(apiUrl, payload);
    if (response.hasOwnProperty("data")) {
      localStorage.setItem("userInfo", JSON.stringify(response.data.user));
      localStorage.setItem(
        "access_token",
        JSON.stringify(response.data.access_token)
      );
      localStorage.setItem(
        "refresh_token",
        JSON.stringify(response.data.refresh_token)
      );
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in authenticate:", error);
    return error;
  }
}

export async function getUserGroupList() {
  try {
    let apiUrl = `/users/group-list`;
    let response = await getData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in getUserGroupList:", error);
  }
}

export async function signUP(payload) {
  try {
    let apiUrl = `/users/register/`;
    let response = await postPublicData(apiUrl, payload);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in signUP:", error);
    return error;
  }
}

export async function getCurrentUserDetails() {
  try {
    let apiUrl = `/users/user/`;
    let response = await getData(apiUrl);
    if (response.hasOwnProperty("data")) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in getCurrentUserDetails:", error);
  }
}

export async function updateProfile(payload) {
  try {
    let apiUrl = `/users/user/`;
    let response = await putDataWithForm(apiUrl, payload);
    if (response.hasOwnProperty("data")) {
      localStorage.setItem("userInfo", JSON.stringify(response.data));
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    developLog("Error in updateProfile:", error);
    return error;
  }
}

export async function getCurrentUserPermissions(
  current_user_info = null,
  group_list_data = null
) {
  try {
    if (current_user_info == null) {
      const responses = await Promise.all([
        getUserGroupList(),
        getCurrentUserDetails(),
      ]);
      var group_list = responses[0];
      var current_user = responses[1];
    } else {
      var group_list = group_list_data;
      var current_user = current_user_info;
    }

    let group_name = null;
    let displayCarts = [];
    let group_id_mapping = {};
    let adminGroupList = [];
    let flag = false;
    for (const [key, value] of Object.entries(group_list)) {
      group_id_mapping[value.id] = value.name;
      if (
        value.name === "DigitalStoreClient" ||
        value.name === "CorporateStoreClient"
      ) {
        continue;
      } else {
        adminGroupList.push(value.id);
      }
    }
    current_user.groups.map((group_id, index) => {
      group_name = group_id_mapping[group_id];
      if (group_name === "DigitalStoreClient") {
        displayCarts.push("digitalOrderCart");
      } else if (group_name === "CorporateStoreClient") {
        displayCarts.push("quotationCart");
      } else if (group_name === "PurchaseOrderAdmin") {
        displayCarts.push("purchaseOrderCart");
      } else if (group_name === "OfflineStoreOrderAdmin") {
        displayCarts.push("offlineOrderCart");
      }
    });
    for (let admin_group in adminGroupList) {
      for (let user_group in current_user.groups) {
        if (adminGroupList[admin_group] === current_user.groups[user_group]) {
          flag = true;
        }
      }
    }

    let result = {
      group_id_mapping: group_id_mapping,
      displayCarts: displayCarts,
      isAdmin: flag,
    };
    return result;
  } catch (error) {
    developLog("Error in getCurrentUserPermissions:", error);
    return {
      group_id_mapping: [],
      displayCarts: [],
      isAdmin: false,
    };
  }
}
