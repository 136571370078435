import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  userGroupReducer,
  adminUserDetailsReducer,
  addNewUserReducer,
} from "reducers/userReducers";
import {
  supplierDepartmentListReducer,
  addNewSupplierDepartmentReducer,
  supplierDepartmentDetailsReducer,
  supplierDepartmentUpdateReducer,
  supplierDepartmentDeleteReducer,
  supplierListReducer,
  addNewSupplierReducer,
  supplierDetailsReducer,
  supplierUpdateReducer,
  supplierDeleteReducer,
} from "reducers/supplierReducer";
import {
  categoryListReducer,
  addNewCategoryReducer,
  categoryDetailsReducer,
  categoryUpdateReducer,
  categoryDeleteReducer,
} from "reducers/catalogueReducers/categoryReducer";

import {
  productTypeListReducer,
  addNewProductTypeReducer,
  productTypeDetailsReducer,
  productTypeUpdateReducer,
  productTypeDeleteReducer,
} from "reducers/catalogueReducers/productTypeReducer";
import {
  UQCListReducer,
  addNewUQCReducer,
  UQCDetailsReducer,
  UQCUpdateReducer,
  UQCDeleteReducer,
} from "reducers/catalogueReducers/uQcReducer";
import {
  unitListReducer,
  addNewUnitReducer,
  unitDetailsReducer,
  unitUpdateReducer,
  unitDeleteReducer,
} from "reducers/catalogueReducers/unitReducer";
import {
  customizationListReducer,
  addNewCustomizationReducer,
  customizationDetailsReducer,
  customizationUpdateReducer,
  customizationDeleteReducer,
} from "reducers/catalogueReducers/customizationReducer";
import {
  productListReducer,
  addNewProductReducer,
  productDetailsReducer,
  productUpdateReducer,
  productDeleteReducer,
} from "reducers/catalogueReducers/productReducer";
import {
  companyDepartmentListReducer,
  addNewCompanyDepartmentReducer,
  companyDepartmentDetailsReducer,
  companyDepartmentUpdateReducer,
  companyDepartmentDeleteReducer,
  companyGstListReducer,
  addNewCompanyGstReducer,
  companyGstDetailsReducer,
  companyGstUpdateReducer,
  companyGstDeleteReducer,
  companyListReducer,
  addNewCompanyReducer,
  companyDetailsReducer,
  companyUpdateReducer,
  companyDeleteReducer,
} from "reducers/companyReducers";
const reducer = combineReducers({
  userLogin: userLoginReducer,
  userGroup: userGroupReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  adminUserDetails: adminUserDetailsReducer,
  addNewUser: addNewUserReducer,
  supplierDepartmentList: supplierDepartmentListReducer,
  addNewSupplierDepartment: addNewSupplierDepartmentReducer,
  supplierDepartmentDetails: supplierDepartmentDetailsReducer,
  supplierDepartmentUpdate: supplierDepartmentUpdateReducer,
  supplierDepartmentDelete: supplierDepartmentDeleteReducer,
  supplierList: supplierListReducer,
  addNewSupplier: addNewSupplierReducer,
  supplierDetails: supplierDetailsReducer,
  supplierUpdate: supplierUpdateReducer,
  supplierDelete: supplierDeleteReducer,
  categoryList: categoryListReducer,
  addNewCategory: addNewCategoryReducer,
  categoryDetails: categoryDetailsReducer,
  categoryUpdate: categoryUpdateReducer,
  categoryDelete: categoryDeleteReducer,
  productTypeList: productTypeListReducer,
  addNewProductType: addNewProductTypeReducer,
  productTypeDetails: productTypeDetailsReducer,
  productTypeUpdate: productTypeUpdateReducer,
  productTypeDelete: productTypeDeleteReducer,
  UQCList: UQCListReducer,
  addNewUQC: addNewUQCReducer,
  UQCDetails: UQCDetailsReducer,
  UQCUpdate: UQCUpdateReducer,
  UQCDelete: UQCDeleteReducer,
  unitList: unitListReducer,
  addNewUnit: addNewUnitReducer,
  unitDetails: unitDetailsReducer,
  unitUpdate: unitUpdateReducer,
  unitDelete: unitDeleteReducer,
  customizationList: customizationListReducer,
  addNewCustomization: addNewCustomizationReducer,
  customizationDetails: customizationDetailsReducer,
  customizationUpdate: customizationUpdateReducer,
  customizationDelete: customizationDeleteReducer,
  productList: productListReducer,
  addNewProduct: addNewProductReducer,
  productDetails: productDetailsReducer,
  productUpdate: productUpdateReducer,
  productDelete: productDeleteReducer,
  companyDepartmentList: companyDepartmentListReducer,
  addNewCompanyDepartment: addNewCompanyDepartmentReducer,
  companyDepartmentDetails: companyDepartmentDetailsReducer,
  companyDepartmentUpdate: companyDepartmentUpdateReducer,
  companyDepartmentDelete: companyDepartmentDeleteReducer,
  companyGstList: companyGstListReducer,
  addNewCompanyGst: addNewCompanyGstReducer,
  companyGstDetails: companyGstDetailsReducer,
  companyGstUpdate: companyGstUpdateReducer,
  companyGstDelete: companyGstDeleteReducer,
  companyList: companyListReducer,
  addNewCompany: addNewCompanyReducer,
  companyDetails: companyDetailsReducer,
  companyUpdate: companyUpdateReducer,
  companyDelete: companyDeleteReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const userGroupInfoStorage = localStorage.getItem("group_id_mapping")
  ? JSON.parse(localStorage.getItem("group_id_mapping"))
  : null;

const initialState = {
  userLogin: { userLoginInfo: userInfoFromStorage },
  userGroup: { userGroupInfo: userGroupInfoStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
