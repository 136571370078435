export const UNIT_LIST_REQUEST = "UNIT_LIST_REQUEST";
export const UNIT_LIST_SUCCESS = "UNIT_LIST_SUCCESS";
export const UNIT_LIST_FAIL = "UNIT_LIST_FAIL";
export const UNIT_LIST_RESET = "UNIT_LIST_RESET";

export const UNIT_DETAIL_REQUEST = "UNIT_DETAIL_REQUEST";
export const UNIT_DETAIL_SUCCESS = "UNIT_DETAIL_SUCCESS";
export const UNIT_DETAIL_FAIL = "UNIT_DETAIL_FAIL";
export const UNIT_DETAIL_RESET = "UNIT_DETAIL_RESET";

export const NEW_UNIT_ADD_REQUEST = "NEW_UNIT_ADD_REQUEST";
export const NEW_UNIT_ADD_SUCCESS = "NEW_UNIT_ADD_SUCCESS";
export const NEW_UNIT_ADD_FAIL = "NEW_UNIT_ADD_FAIL";

export const UNIT_UPDATE_REQUEST = "UNIT_UPDATE_REQUEST";
export const UNIT_UPDATE_SUCCESS = "UNIT_UPDATE_SUCCESS";
export const UNIT_UPDATE_FAIL = "UNIT_UPDATE_FAIL";
export const UNIT_UPDATE_RESET = "UNIT_UPDATE_RESET";

export const UNIT_DELETE_REQUEST = "UNIT_DELETE_REQUEST";
export const UNIT_DELETE_SUCCESS = "UNIT_DELETE_SUCCESS";
export const UNIT_DELETE_FAIL = "UNIT_DELETE_FAIL";
