import {
  theme as base,
  extendTheme,
  withDefaultColorScheme,
  withDefaultVariant,
} from "@chakra-ui/react";

import { mode } from "@chakra-ui/theme-tools";

const inputSelectStyles = {
  variants: {
    filled: {
      field: {
        _focus: {
          //backgroundColor: "black",
          // color: "white"
        },
      },
    },
  },
  sizes: {
    md: {
      field: {
        borderRadius: "none",
      },
    },
  },
};

const brandRing = {
  _focus: {
    ring: 2,
    ringColor: "brand.500",
  },
};
const theme = extendTheme(
  {
    styles: {
      global: (props) => ({
        ".global-title": {
          fontSize: "xl",
          fontWeight: "bold",
          color: mode("black", "white")(props), // Automatically adjusts based on colorMode
          mt: "-1rem",
          mb: "-1rem",
        },
        ".table-border": {
          border: "1px solid",
          borderColor: props.colorMode === "light" ? "gray.300" : "gray.700",
          borderRadius: "md",
        },
      }),
    },
    colors: {
      brand: {
        50: "#f5fee5",
        100: "#e1fbb2",
        200: "#cdf781",
        300: "#b8ee56",
        400: "#a2e032",
        500: "#8ac919",
        600: "#71ab09",
        700: "#578602",
        800: "#3c5e00",
        900: "#203300",
      },
    },
    fonts: {
      heading: `Montserrat, ${base.fonts?.heading}`,
      body: `Inter, ${base.fonts?.body}`,
    },
    fontSizes: {
      xs: "0.75rem", // Extra small screens
      sm: "0.875rem", // Small screens
      md: "1rem", // Medium screens
      lg: "1.125rem", // Large screens
      xl: "1.25rem", // Extra large screens
      "2xl": "1.5rem", // 2X large screens
      "3xl": "1.875rem", // 3X large screens
      // ... Define more sizes as needed
    },
    components: {
      Button: {
        baseStyle: {
          size: {
            base: "sm",
            md: "md",
          },
        },
        variants: {
          primary: (props) => ({
            // rounded: "none",
            borderRadius: "0.5rem",
            ...brandRing,
            color: mode("white", "gray.800")(props),
            backgroundColor: mode("#858fe3", "brand.200")(props),

            _hover: {
              backgroundColor: mode("#6b75b8", "brand.300")(props),
            },

            _active: {
              backgroundColor: mode("brand.700", "brand.400")(props),
            },
          }),
        },
      },
      Input: { ...inputSelectStyles },
      Select: {
        ...inputSelectStyles,
        baseStyle: {
          bg: mode("white", "gray.800"),
          color: mode("black", "white"),
          _placeholder: {
            color: mode("gray.500", "gray.400"),
          },
          borderColor: mode("gray.300", "gray.600"),
          _hover: {
            borderColor: mode("gray.400", "gray.500"),
          },
          _focus: {
            boxShadow: "0 0 0 2px rgba(16, 185, 129, 0.3)",
          },
        },
      },
      Checkbox: {
        baseStyle: {
          control: {
            borderRadius: "none",
            ...brandRing,
          },
        },
      },
    },
  },
  withDefaultColorScheme({
    colorScheme: "brand",
    components: ["Checkbox"],
  }),
  withDefaultVariant({
    variant: "filled",
    components: ["Input", "Select"],
  })
);

export default theme;
