import {
  Button,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { developLog, titleCase } from "utils";

import { FaGift } from "react-icons/fa";
import { getUserParentCategories } from "api/catlogue";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "@chakra-ui/react";

const CategoryMenu = () => {
  const [categories, setCategories] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMobileView] = useMediaQuery("(max-width: 48em)");
  const location = useLocation();

  // Check if the current route is the home page ("/")
  const isHomePage = location.pathname === "/";
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoryData = await getUserParentCategories();
        setCategories(categoryData);
        setLoading(false);
      } catch (error) {
        developLog("Error:", error);
        setLoading(false);
      }
    };
    if (!isHomePage) {
      fetchCategories();
    }
  }, []);

  if (loading) {
    // Show a loading state or placeholder while fetching categories
    return <div>Loading...</div>;
  }

  return (
    <Menu>
      {isMobileView ? (
        <MenuButton
          textAlign="left"
          variant="ghost"
          as={Button}
          leftIcon={<FaGift />}
        >
          Products
        </MenuButton>
      ) : (
        <MenuButton textAlign="left">Products</MenuButton>
      )}

      <MenuList>
        {categories &&
          categories.map((category, idx) => (
            <MenuItem key={idx}>
              <Link href={"/products/" + category.slug}>
                {titleCase(category.name)}
              </Link>
            </MenuItem>
          ))}
      </MenuList>
    </Menu>
  );
};

export default CategoryMenu;
