export const CUSTOMIZATION_LIST_REQUEST = "CUSTOMIZATION_LIST_REQUEST";
export const CUSTOMIZATION_LIST_SUCCESS = "CUSTOMIZATION_LIST_SUCCESS";
export const CUSTOMIZATION_LIST_FAIL = "CUSTOMIZATION_LIST_FAIL";
export const CUSTOMIZATION_LIST_RESET = "CUSTOMIZATION_LIST_RESET";

export const CUSTOMIZATION_DETAIL_REQUEST = "CUSTOMIZATION_DETAIL_REQUEST";
export const CUSTOMIZATION_DETAIL_SUCCESS = "CUSTOMIZATION_DETAIL_SUCCESS";
export const CUSTOMIZATION_DETAIL_FAIL = "CUSTOMIZATION_DETAIL_FAIL";
export const CUSTOMIZATION_DETAIL_RESET = "CUSTOMIZATION_DETAIL_RESET";

export const NEW_CUSTOMIZATION_ADD_REQUEST = "NEW_CUSTOMIZATION_ADD_REQUEST";
export const NEW_CUSTOMIZATION_ADD_SUCCESS = "NEW_CUSTOMIZATION_ADD_SUCCESS";
export const NEW_CUSTOMIZATION_ADD_FAIL = "NEW_CUSTOMIZATION_ADD_FAIL";

export const CUSTOMIZATION_UPDATE_REQUEST = "CUSTOMIZATION_UPDATE_REQUEST";
export const CUSTOMIZATION_UPDATE_SUCCESS = "CUSTOMIZATION_UPDATE_SUCCESS";
export const CUSTOMIZATION_UPDATE_FAIL = "CUSTOMIZATION_UPDATE_FAIL";
export const CUSTOMIZATION_UPDATE_RESET = "CUSTOMIZATION_UPDATE_RESET";

export const CUSTOMIZATION_DELETE_REQUEST = "CUSTOMIZATION_DELETE_REQUEST";
export const CUSTOMIZATION_DELETE_SUCCESS = "CUSTOMIZATION_DELETE_SUCCESS";
export const CUSTOMIZATION_DELETE_FAIL = "CUSTOMIZATION_DELETE_FAIL";
