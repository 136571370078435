import {
  PRODUCT_TYPE_LIST_REQUEST,
  PRODUCT_TYPE_LIST_SUCCESS,
  PRODUCT_TYPE_LIST_FAIL,
  PRODUCT_TYPE_LIST_RESET,
  PRODUCT_TYPE_DETAIL_REQUEST,
  PRODUCT_TYPE_DETAIL_SUCCESS,
  PRODUCT_TYPE_DETAIL_FAIL,
  PRODUCT_TYPE_DETAIL_RESET,
  NEW_PRODUCT_TYPE_ADD_REQUEST,
  NEW_PRODUCT_TYPE_ADD_SUCCESS,
  NEW_PRODUCT_TYPE_ADD_FAIL,
  PRODUCT_TYPE_UPDATE_REQUEST,
  PRODUCT_TYPE_UPDATE_SUCCESS,
  PRODUCT_TYPE_UPDATE_FAIL,
  PRODUCT_TYPE_UPDATE_RESET,
  PRODUCT_TYPE_DELETE_REQUEST,
  PRODUCT_TYPE_DELETE_SUCCESS,
  PRODUCT_TYPE_DELETE_FAIL,
} from "constants/catalogueConstants/productTypeConstants";

export const productTypeListReducer = (
  state = { productTypeListDetails: [], loading: true },
  action
) => {
  switch (action.type) {
    case PRODUCT_TYPE_LIST_REQUEST:
      return { loading: true };

    case PRODUCT_TYPE_LIST_SUCCESS:
      return { loading: false, productTypeListDetails: action.payload };

    case PRODUCT_TYPE_LIST_FAIL:
      return { loading: false, error: action.payload };

    case PRODUCT_TYPE_LIST_RESET:
      return { productTypeListDetails: [], loading: true };

    default:
      return state;
  }
};

export const addNewProductTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_PRODUCT_TYPE_ADD_REQUEST:
      return { loading: true };

    case NEW_PRODUCT_TYPE_ADD_SUCCESS:
      return { loading: false, newProductTypeAddInfo: action.payload };

    case NEW_PRODUCT_TYPE_ADD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const productTypeDetailsReducer = (
  state = { productTypeInfo: {}, loading: true },
  action
) => {
  switch (action.type) {
    case PRODUCT_TYPE_DETAIL_REQUEST:
      return { ...state, loading: true };

    case PRODUCT_TYPE_DETAIL_SUCCESS:
      return { loading: false, productTypeInfo: action.payload };

    case PRODUCT_TYPE_DETAIL_FAIL:
      return { loading: false, error: action.payload };

    case PRODUCT_TYPE_DETAIL_RESET:
      return { productTypeInfo: {} };

    default:
      return state;
  }
};

export const productTypeUpdateReducer = (
  state = { productTypeUpdateDetails: {} },
  action
) => {
  switch (action.type) {
    case PRODUCT_TYPE_UPDATE_REQUEST:
      return { loading: true };

    case PRODUCT_TYPE_UPDATE_SUCCESS:
      return {
        loading: false,
        updateSuccess: true,
        productTypeUpdateDetails: action.payload,
      };

    case PRODUCT_TYPE_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case PRODUCT_TYPE_UPDATE_RESET:
      return { productTypeUpdateDetails: [] };

    default:
      return state;
  }
};

export const productTypeDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_TYPE_DELETE_REQUEST:
      return { loading: true };

    case PRODUCT_TYPE_DELETE_SUCCESS:
      return { loading: false, success: true };

    case PRODUCT_TYPE_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
