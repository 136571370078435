export const PRODUCT_TYPE_LIST_REQUEST = "PRODUCT_TYPE_LIST_REQUEST";
export const PRODUCT_TYPE_LIST_SUCCESS = "PRODUCT_TYPE_LIST_SUCCESS";
export const PRODUCT_TYPE_LIST_FAIL = "PRODUCT_TYPE_LIST_FAIL";
export const PRODUCT_TYPE_LIST_RESET = "PRODUCT_TYPE_LIST_RESET";

export const PRODUCT_TYPE_DETAIL_REQUEST = "PRODUCT_TYPE_DETAIL_REQUEST";
export const PRODUCT_TYPE_DETAIL_SUCCESS = "PRODUCT_TYPE_DETAIL_SUCCESS";
export const PRODUCT_TYPE_DETAIL_FAIL = "PRODUCT_TYPE_DETAIL_FAIL";
export const PRODUCT_TYPE_DETAIL_RESET = "PRODUCT_TYPE_DETAIL_RESET";

export const NEW_PRODUCT_TYPE_ADD_REQUEST = "NEW_PRODUCT_TYPE_ADD_REQUEST";
export const NEW_PRODUCT_TYPE_ADD_SUCCESS = "NEW_PRODUCT_TYPE_ADD_SUCCESS";
export const NEW_PRODUCT_TYPE_ADD_FAIL = "NEW_PRODUCT_TYPE_ADD_FAIL";

export const PRODUCT_TYPE_UPDATE_REQUEST = "PRODUCT_TYPE_UPDATE_REQUEST";
export const PRODUCT_TYPE_UPDATE_SUCCESS = "PRODUCT_TYPE_UPDATE_SUCCESS";
export const PRODUCT_TYPE_UPDATE_FAIL = "PRODUCT_TYPE_UPDATE_FAIL";
export const PRODUCT_TYPE_UPDATE_RESET = "PRODUCT_TYPE_UPDATE_RESET";

export const PRODUCT_TYPE_DELETE_REQUEST = "PRODUCT_TYPE_DELETE_REQUEST";
export const PRODUCT_TYPE_DELETE_SUCCESS = "PRODUCT_TYPE_DELETE_SUCCESS";
export const PRODUCT_TYPE_DELETE_FAIL = "PRODUCT_TYPE_DELETE_FAIL";
