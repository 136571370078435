export const UQC_LIST_REQUEST = "UQC_LIST_REQUEST";
export const UQC_LIST_SUCCESS = "UQC_LIST_SUCCESS";
export const UQC_LIST_FAIL = "UQC_LIST_FAIL";
export const UQC_LIST_RESET = "UQC_LIST_RESET";

export const UQC_DETAIL_REQUEST = "UQC_DETAIL_REQUEST";
export const UQC_DETAIL_SUCCESS = "UQC_DETAIL_SUCCESS";
export const UQC_DETAIL_FAIL = "UQC_DETAIL_FAIL";
export const UQC_DETAIL_RESET = "UQC_DETAIL_RESET";

export const NEW_UQC_ADD_REQUEST = "NEW_UQC_ADD_REQUEST";
export const NEW_UQC_ADD_SUCCESS = "NEW_UQC_ADD_SUCCESS";
export const NEW_UQC_ADD_FAIL = "NEW_UQC_ADD_FAIL";

export const UQC_UPDATE_REQUEST = "UQC_UPDATE_REQUEST";
export const UQC_UPDATE_SUCCESS = "UQC_UPDATE_SUCCESS";
export const UQC_UPDATE_FAIL = "UQC_UPDATE_FAIL";
export const UQC_UPDATE_RESET = "UQC_UPDATE_RESET";

export const UQC_DELETE_REQUEST = "UQC_DELETE_REQUEST";
export const UQC_DELETE_SUCCESS = "UQC_DELETE_SUCCESS";
export const UQC_DELETE_FAIL = "UQC_DELETE_FAIL";
