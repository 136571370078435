import {
  DEPARTMENT_LIST_REQUEST,
  DEPARTMENT_LIST_SUCCESS,
  DEPARTMENT_LIST_FAIL,
  DEPARTMENT_LIST_RESET,
  DEPARTMENT_DETAIL_REQUEST,
  DEPARTMENT_DETAIL_SUCCESS,
  DEPARTMENT_DETAIL_FAIL,
  DEPARTMENT_DETAIL_RESET,
  NEW_DEPARTMENT_ADD_REQUEST,
  NEW_DEPARTMENT_ADD_SUCCESS,
  NEW_DEPARTMENT_ADD_FAIL,
  DEPARTMENT_UPDATE_REQUEST,
  DEPARTMENT_UPDATE_SUCCESS,
  DEPARTMENT_UPDATE_FAIL,
  DEPARTMENT_UPDATE_RESET,
  DEPARTMENT_DELETE_REQUEST,
  DEPARTMENT_DELETE_SUCCESS,
  DEPARTMENT_DELETE_FAIL,
  SUPPLIER_LIST_REQUEST,
  SUPPLIER_LIST_SUCCESS,
  SUPPLIER_LIST_FAIL,
  SUPPLIER_LIST_RESET,
  SUPPLIER_DETAIL_REQUEST,
  SUPPLIER_DETAIL_SUCCESS,
  SUPPLIER_DETAIL_FAIL,
  SUPPLIER_DETAIL_RESET,
  NEW_SUPPLIER_ADD_REQUEST,
  NEW_SUPPLIER_ADD_SUCCESS,
  NEW_SUPPLIER_ADD_FAIL,
  SUPPLIER_UPDATE_REQUEST,
  SUPPLIER_UPDATE_SUCCESS,
  SUPPLIER_UPDATE_FAIL,
  SUPPLIER_UPDATE_RESET,
  SUPPLIER_DELETE_REQUEST,
  SUPPLIER_DELETE_SUCCESS,
  SUPPLIER_DELETE_FAIL,
} from "constants/supplierConstants";

export const supplierDepartmentListReducer = (
  state = { departmentListDetails: [], loading: true },
  action
) => {
  switch (action.type) {
    case DEPARTMENT_LIST_REQUEST:
      return { loading: true };

    case DEPARTMENT_LIST_SUCCESS:
      return { loading: false, departmentListDetails: action.payload };

    case DEPARTMENT_LIST_FAIL:
      return { loading: false, error: action.payload };

    case DEPARTMENT_LIST_RESET:
      return { departmentListDetails: [], loading: true };

    default:
      return state;
  }
};

export const addNewSupplierDepartmentReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_DEPARTMENT_ADD_REQUEST:
      return { loading: true };

    case NEW_DEPARTMENT_ADD_SUCCESS:
      return { loading: false, newSupplierDepartmentAddInfo: action.payload };

    case NEW_DEPARTMENT_ADD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const supplierDepartmentDetailsReducer = (
  state = { supplierDepartmentInfo: {}, loading: true },
  action
) => {
  switch (action.type) {
    case DEPARTMENT_DETAIL_REQUEST:
      return { ...state, loading: true };

    case DEPARTMENT_DETAIL_SUCCESS:
      return { loading: false, supplierDepartmentInfo: action.payload };

    case DEPARTMENT_DETAIL_FAIL:
      return { loading: false, error: action.payload };

    case DEPARTMENT_DETAIL_RESET:
      return { supplierDepartmentInfo: {} };

    default:
      return state;
  }
};

export const supplierDepartmentUpdateReducer = (
  state = { supplierDepartmentUpdateDetails: {} },
  action
) => {
  switch (action.type) {
    case DEPARTMENT_UPDATE_REQUEST:
      return { loading: true };

    case DEPARTMENT_UPDATE_SUCCESS:
      return {
        loading: false,
        updateSuccess: true,
        supplierDepartmentUpdateDetails: action.payload,
      };

    case DEPARTMENT_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case DEPARTMENT_UPDATE_RESET:
      return { supplierDepartmentUpdateDetails: [] };

    default:
      return state;
  }
};

export const supplierDepartmentDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DEPARTMENT_DELETE_REQUEST:
      return { loading: true };

    case DEPARTMENT_DELETE_SUCCESS:
      return { loading: false, success: true };

    case DEPARTMENT_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const supplierListReducer = (
  state = { supplierListDetails: [], loading: true },
  action
) => {
  switch (action.type) {
    case SUPPLIER_LIST_REQUEST:
      return { loading: true };

    case SUPPLIER_LIST_SUCCESS:
      return { loading: false, supplierListDetails: action.payload };

    case SUPPLIER_LIST_FAIL:
      return { loading: false, error: action.payload };

    case SUPPLIER_LIST_RESET:
      return { supplierListDetails: [], loading: true };

    default:
      return state;
  }
};

export const addNewSupplierReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_SUPPLIER_ADD_REQUEST:
      return { loading: true };

    case NEW_SUPPLIER_ADD_SUCCESS:
      return { loading: false, newSupplierAddInfo: action.payload };

    case NEW_SUPPLIER_ADD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const supplierDetailsReducer = (
  state = { supplierInfo: {}, loading: true },
  action
) => {
  switch (action.type) {
    case SUPPLIER_DETAIL_REQUEST:
      return { ...state, loading: true };

    case SUPPLIER_DETAIL_SUCCESS:
      return { loading: false, supplierInfo: action.payload };

    case SUPPLIER_DETAIL_FAIL:
      return { loading: false, error: action.payload };

    case SUPPLIER_DETAIL_RESET:
      return { supplierInfo: {} };

    default:
      return state;
  }
};

export const supplierUpdateReducer = (
  state = { supplierUpdateDetails: {} },
  action
) => {
  switch (action.type) {
    case SUPPLIER_UPDATE_REQUEST:
      return { loading: true };

    case SUPPLIER_UPDATE_SUCCESS:
      return {
        loading: false,
        updateSuccess: true,
        supplierUpdateDetails: action.payload,
      };

    case SUPPLIER_UPDATE_FAIL:
      return { loading: false, updateError: action.payload };

    case SUPPLIER_UPDATE_RESET:
      return { supplierUpdateDetails: [] };

    default:
      return state;
  }
};

export const supplierDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SUPPLIER_DELETE_REQUEST:
      return { loading: true };

    case SUPPLIER_DELETE_SUCCESS:
      return { loading: false, success: true };

    case SUPPLIER_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
