import axios from "axios";
import gstMapping from "data/gst_state_code_mapping.json";
import { logout } from "actions/userActions";
import store from "store";
export const baseURL = `${process.env.REACT_APP_API_URL}`;

export const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem("access_token"));
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    developLog("auth error:", error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/loging" && err.response) {
      // developLog(originalConfig);
      // Access Token was expired
      if (
        (err.response.status === 401 || err.response.status === 403) &&
        !originalConfig._retry &&
        originalConfig.url !== "/users/token/refresh/"
      ) {
        originalConfig._retry = true;

        try {
          const rs = await axiosInstance.post("/users/token/refresh/", {
            refresh: JSON.parse(localStorage.getItem("refresh_token")),
          });
          axiosInstance.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${rs.data.access}`;
          localStorage.setItem("access_token", JSON.stringify(rs.data.access));
          return axiosInstance(originalConfig);
        } catch (_error) {
          store.dispatch(logout());
          // developLog("Invoke logout here");
          window.location.replace(`${process.env.REACT_APP_BASE_URL}/login`);
          return Promise.reject(_error);
        }
      }
      if (String(originalConfig.url) === String("/users/token/refresh/")) {
        // window.location.replace(`${process.env.REACT_APP_BASE_URL}/login`);
        store.dispatch(logout());
        // developLog("Invoke logout here");
        window.location.replace(`${process.env.REACT_APP_BASE_URL}/login`);
      }
    }

    return Promise.reject(err);
  }
);

export async function getData(url = "") {
  let access_token = JSON.parse(localStorage.getItem("access_token"));
  let apiUrl = `${baseURL}${url}`;
  if (url.includes("://")) {
    apiUrl = `${url}`;
  }

  let config = {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  };
  try {
    let response = await axiosInstance.get(apiUrl, config);
    return response;
  } catch (e) {
    developLog("Error in getData:", e);
    return e;
  }
}

export async function getPublicData(url = "") {
  let apiUrl = `${baseURL}${url}`;
  if (url.includes("://")) {
    apiUrl = `${url}`;
  }

  let config = {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  };
  try {
    let response = await axiosInstance.get(apiUrl, config);
    return response;
  } catch (e) {
    developLog("Error in getData:", e);
    return e;
  }
}
export async function postData(url = "", data = {}) {
  let access_token = JSON.parse(localStorage.getItem("access_token"));

  let apiUrl = `${baseURL}${url}`;

  let config = {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // Authorization: `${token}`
      Authorization: `Bearer ${access_token}`,
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    // body: JSON.stringify(data) // body data type must match "Content-Type" header
  };

  try {
    let response = await axiosInstance.post(
      apiUrl,
      JSON.stringify(data),
      config
    );
    return response;
  } catch (e) {
    return e;
  }
}
export async function postPublicData(url = "", data = {}) {
  let apiUrl = `${baseURL}${url}`;

  let config = {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // Authorization: `${token}`
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    // body: JSON.stringify(data) // body data type must match "Content-Type" header
  };

  try {
    let response = await axiosInstance.post(
      apiUrl,
      JSON.stringify(data),
      config
    );
    return response;
  } catch (e) {
    return e;
  }
}
export async function postDataWithForm(url = "", data = {}) {
  let access_token = JSON.parse(localStorage.getItem("access_token"));

  let apiUrl = `${baseURL}${url}`;

  let config = {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit

    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${access_token}`,
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    // body: JSON.stringify(data) // body data type must match "Content-Type" header
    // data: data,
  };

  try {
    let response = await axiosInstance.post(apiUrl, data, config);
    return response;
  } catch (e) {
    return e;
  }
}

export async function postPublicDataWithForm(url = "", data = {}) {
  let apiUrl = `${baseURL}${url}`;
  if (url.includes("://")) {
    apiUrl = `${url}`;
  }
  let config = {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit

    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    // body: JSON.stringify(data) // body data type must match "Content-Type" header
    // data: data,
  };

  try {
    let response = await axiosInstance.post(apiUrl, data, config);
    return response;
  } catch (e) {
    return e;
  }
}

export async function putData(url = "", data = {}) {
  let access_token = JSON.parse(localStorage.getItem("access_token"));

  let apiUrl = `${baseURL}${url}`;
  let config = {
    method: "PUT", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // Authorization: `${token}`
      Authorization: `Bearer ${access_token}`,
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    // body: JSON.stringify(data) // body data type must match "Content-Type" header
  };
  try {
    let response = await axiosInstance.put(
      apiUrl,
      JSON.stringify(data),
      config
    );
    return response;
  } catch (e) {
    developLog("Error in putData:", e);
    return e;
  }
}
export async function putDataWithForm(url = "", data = {}) {
  let access_token = JSON.parse(localStorage.getItem("access_token"));

  let apiUrl = `${baseURL}${url}`;

  let config = {
    method: "PUT", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit

    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${access_token}`,
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    // body: JSON.stringify(data) // body data type must match "Content-Type" header
    // data: data,
  };

  try {
    let response = await axiosInstance.put(apiUrl, data, config);
    return response;
  } catch (e) {
    return e;
  }
}
export async function patchData(url = "", data = {}) {
  let access_token = JSON.parse(localStorage.getItem("access_token"));

  let apiUrl = `${baseURL}${url}`;
  let config = {
    method: "PATCH", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // Authorization: `${token}`
      Authorization: `Bearer ${access_token}`,
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    // body: JSON.stringify(data) // body data type must match "Content-Type" header
  };
  try {
    let response = await axiosInstance.patch(
      apiUrl,
      JSON.stringify(data),
      config
    );
    return response;
  } catch (e) {
    developLog("Error in patchData:", e);
    return e;
  }
}

export async function deleteData(url = "") {
  let access_token = JSON.parse(localStorage.getItem("access_token"));

  let apiUrl = `${baseURL}${url}`;
  let config = {
    method: "DELETE", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // Authorization: `${token}`
      Authorization: `Bearer ${access_token}`,
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  };
  try {
    let response = await axiosInstance.delete(apiUrl, config);
    return response;
  } catch (e) {
    developLog("Error in deleteData:", e);
    return e;
  }
}

export function parseErrors(response) {
  try {
    let errors_messages = [];
    for (const [key, value] of Object.entries(response.response.data)) {
      if (Array.isArray(value)) {
        errors_messages.push(...value);
      } else {
        for (const [key_nested, value_nested] of Object.entries(value)) {
          errors_messages.push(...value_nested);
        }
      }
    }
    return errors_messages;
  } catch (error) {
    developLog("parseErrors Error:", error);
    return ["Error in request"];
  }
}

export function replacePageParameter(base_url, page) {
  try {
    var url = new URL(base_url);
    var search_params = url.searchParams;
    search_params.set("page", page);
    url.search = search_params.toString();
    var new_url = url.toString();
    // developLog(new_url);
    return new_url;
  } catch (error) {
    developLog("Error in replace page parameter:", error);
  }
}
export function getCurrentPageNumber(response) {
  try {
    // developLog("get current page number params:", response);
    if (response && response.hasOwnProperty("previous")) {
      if (response.previous === null) {
        return 1;
      } else {
        var previous_link = response.previous;
        var url = new URL(previous_link);
        var page = url.searchParams.get("page");
        if (page === null) {
          return 2;
        }

        page = parseInt(page) + 1;
        return page;
      }
    } else {
      return 1;
    }
  } catch (error) {
    developLog("Error in get page number", error);
  }
}

export function developLog(...args) {
  if (process.env.REACT_APP_ENV === "development") {
    console.log(...args);
  }
}

export function titleCase(str) {
  return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
}

export function displayprice(MRP, discount_price, regular_price) {
  try {
    let displayPrice = {
      price: 0,
      display_price: false,
      discountPrice: 0,
      display_discountPrice: false,
    };
    if (MRP > 0 && regular_price > 0 && discount_price > 0) {
      displayPrice.price = MRP;
      displayPrice.discountPrice = discount_price;
      displayPrice.display_price = true;
      displayPrice.display_discountPrice = true;
    } else if (MRP > 0 && regular_price > 0 && parseInt(discount_price) === 0) {
      displayPrice.price = MRP;
      displayPrice.display_price = true;
      displayPrice.display_discountPrice = false;
    } else if (parseInt(MRP) === 0 && regular_price > 0 && discount_price > 0) {
      displayPrice.price = regular_price;
      displayPrice.discountPrice = discount_price;
      displayPrice.display_price = true;
      displayPrice.display_discountPrice = true;
    } else if (
      parseInt(MRP) === 0 &&
      regular_price > 0 &&
      discount_price === 0
    ) {
      displayPrice.price = regular_price;
      displayPrice.display_price = true;
      displayPrice.display_discountPrice = false;
    }

    return displayPrice;
  } catch (error) {
    developLog("Error in display price:", error);
  }
}
export function isNumeric(value) {
  try {
    return /^\d+\.\d+$/.test(value);
  } catch (error) {
    developLog("Error in isNumeric:", error);
  }
}

export function formatDate(date) {
  return date.toLocaleDateString(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}
export function formatDateTime(date) {
  return date.toLocaleDateString(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });
}
export function calculateEstimatedDeliveryDate(updatedAt) {
  const deliveryDays = 4; // 4 working days for delivery
  const updatedAtDate = new Date(updatedAt);
  const estimatedDeliveryDate = new Date(updatedAtDate);
  let count = 0;

  // Calculate the estimated delivery date by adding working days
  while (count < deliveryDays) {
    estimatedDeliveryDate.setDate(estimatedDeliveryDate.getDate() + 1);
    // Check if the day is a weekday (Monday to Friday)
    if (
      estimatedDeliveryDate.getDay() !== 0 &&
      estimatedDeliveryDate.getDay() !== 6
    ) {
      count++;
    }
  }

  return estimatedDeliveryDate;
}

export function formatIndianCurrency(number) {
  if (isNaN(number)) return "0";

  const formattedNumber = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
  }).format(number);

  return formattedNumber;
}

export function href_to_relative_link(url) {
  return url.replace(`${process.env.REACT_APP_BASE_URL}`, "");
}

export function round_up(num) {
  return Math.round(num * 100) / 100;
}

export function get_gst_location_details(gst_number) {
  try {
    const cleanedGST = gst_number.replace(/\s/g, "").slice(0, 2);

    // Search for the code in the mapping
    for (const [state, code] of Object.entries(gstMapping)) {
      if (code === cleanedGST) {
        return { state, code };
      }
    }

    // If code not found, return null
    return { state: null, code: null };
  } catch (error) {
    developLog("Error in get_gst_location_details:", error);
    return { state: null, code: null };
  }
}

export function getOrderStatusDescription(order_status, store_pickup) {
  switch (order_status) {
    case "Pending":
      return "Your order has been created, but payment is pending. Please complete the payment to proceed.";
    case "Placed":
      return "Payment for your order has been successfully received. We're processing your order now.";
    case "Ready to be dispatched":
      if (store_pickup) {
        return "Your order is ready and available for pickup from the store during working hours (Mon-Sat 10am to 6pm).";
      } else {
        return "Your order is packed and ready to be shipped. It will be dispatched shortly.";
      }

    case "Dispatched":
      return "Your order has been picked up by the courier and is on its way to you.";
    case "Completed":
      return "Congratulations! Your order has been successfully delivered to you.";
    case "Cancelled":
      return "Your order has been cancelled at your request. No further action will be taken.";
    case "Force Cancelled":
      return "Your order has been forcefully cancelled due to unforeseen circumstances. We apologize for any inconvenience caused.";
    default:
      return "We're sorry, but the status of your order is currently unavailable. Please contact support for assistance.";
  }
}

export function validatePhoneNumber(phoneNumber) {
  // Regular expression to match Indian phone number
  const phoneNumberRegex = /^[6-9]\d{9}$/;
  return phoneNumberRegex.test(phoneNumber);
}
