import React, { Suspense, useEffect, useState } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import { getMessaging, getToken } from "firebase/messaging";

import { AuthProvider } from "context/AuthContext";
import { ChakraProvider } from "@chakra-ui/react";
import Footer from "components/footer";
import { HelmetProvider } from "react-helmet-async";
import Navbar from "components/navbar/Navbar_chakra";
import PrivateRoute from "PrivateRoute";

import { UIProvider } from "context/UIContext";
import { developLog } from "utils";
import { firebaseConfig } from "firebaseConfig";
import { initializeApp } from "firebase/app";
import { registerDevice } from "api/userApis";
import routes from "routes";
import theme from "theme";
import { useSelector } from "react-redux";

function App() {
  const [themes, setThemes] = useState("");
  const userLogin = useSelector((state) => state.userLogin);
  const { userLoginInfo } = userLogin;
  useEffect(() => {
    const themesData = JSON.parse(localStorage.getItem("themes"));
    if (themesData) {
      setThemes(themesData);
    }
  }, []);
  useEffect(() => {
    localStorage.setItem("themes", JSON.stringify(themes));
  }, [themes]);

  useEffect(() => {
    try {
      const app = initializeApp(firebaseConfig);
      const messaging = getMessaging(app);

      getToken(messaging, { vapidKey: `${process.env.REACT_APP_vapidKey}` })
        .then(async (currentToken) => {
          if (currentToken) {
            if (userLoginInfo) {
              let payload = {
                registration_id: currentToken,
                type: "web",
                device_id: userLoginInfo.pk,
                active: true,
                name: userLoginInfo.email,
              };
              await registerDevice(payload);
            }
            // Send the token to your server and update the UI if necessary
            // ...
          } else {
            // Show permission request UI
            developLog(
              "No registration token available. Request permission to generate one."
            );
            // ...
          }
        })
        .catch((err) => {
          developLog("An error occurred while retrieving token. ", err);
          // ...
        });
    } catch (err) {
      developLog("Push notification errors:", err);
    }
  }, [userLoginInfo]);

  const routeComponents = routes.map(
    ({ path, component, ...otherRouteProps }, key) =>
      otherRouteProps.routeType === "private" ? (
        <PrivateRoute exact path={path} component={component} key={key} />
      ) : (
        <Route exact path={path} component={component} key={key} />
      )
  );
  return (
    <ChakraProvider theme={theme}>
      <UIProvider>
        <Router>
          <HelmetProvider>
            <AuthProvider>
              <Navbar />
              <Suspense fallback={<div>Loading...</div>}>
                {routeComponents}
              </Suspense>
              <Footer />
            </AuthProvider>
          </HelmetProvider>
        </Router>
      </UIProvider>
    </ChakraProvider>
  );
}

export default App;
